import PopupConfirmWithoutRedirect from "../components/popup-message/popup-confirm-without-rediderc.component";
import GenericPopupWithSelectBox from "../components/popup-message/generic-popup-with-select-box.component";
import PopupMessage from "../components/popup-message/popup-message.component";
import axios from "axios";
import ENDPOINTS from "../utils/endpoints";
import LoadingPopUp from "../components/popup-message/loading-popup.component";

export default class DelayContact {
  #taskId = null;
  #preparedConfig = {};
  #reasons = {
    1: "Cliente no contesta",
    2: "Cliente solicita llamada otro día",
    3: "Cliente de vacaciones",
    4: "Reparador falta de agenda",
    6: "Otros",
  };
  #selectedReason = null;

  constructor(taskId, preparedConfig, selectedReason = null) {
    this.#taskId = taskId;
    this.#preparedConfig = preparedConfig;
    this.#selectedReason = selectedReason;
  }

  async delayContact() {
    await PopupConfirmWithoutRedirect(
      "question",
      "¿Esta seguro de que quiere aplazar el contacto con el cliente?",
      "Si",
      "No",
      "mh-modal3",
      (result) => {
        if (result["value"]) {
          this.askReasonDelayContact();
        }
      }
    );
  }

  async askReasonDelayContact() {
    await GenericPopupWithSelectBox(
      "question",
      "Indica que se ha determinado en el diagnóstico ",
      "Confirmar",
      "Cancelar",
      this.#reasons,
      "Seleccione una opción",
      "mh-modal3",
      (result) => {
        this.#selectedReason = parseInt(result.value);
        this.processDelayContact();
      }
    );
  }

  async processDelayContact() {
    // El primer parametro se definio para modificar la fecha de ejecución y el segundo para proponer la cita
    // En este caso, aprovecharemos el segundo parametro con un 2 para especificar que se aplazá
    switch (this.#selectedReason) {
      case 1:
      case 4:
        await PopupConfirmWithoutRedirect(
          "question",
          "La opción elegida pospone le contacto 24 horas, ¿Está seguro de que quiere aplazar el contacto con el cliente?",
          "Si",
          "No",
          "mh-modal3",
          (result) => {
            if (result["value"]) {
              this.delayContact24h("servicios");
            }
          }
        );
        break;
      default:
        this.showCalendar();
    }
  }

  delayContact24h(redirect = "calendario") {
    this.delayContactApiCall(
      {
        MultihelpersJobCode: this.#taskId,
        reason: this.#selectedReason,
        delay24h: true,
      },
      redirect,
      "Se ha pospuesto correctamente 24h el contacto con el cliente"
    );
  }

  showCalendar() {
    localStorage.setItem("reason", this.#selectedReason);
    // Se especifica un 2 en askReason para dar a entender a la página que ya se ha preguntado por la razón y esta esta almacenada en local storage
    window.location = "/" + this.#taskId + "/cambiar_fecha/2/2";
  }

  delayContactApiCall(
    dataJSON,
    redirect = "calendario",
    sucess_text = "Se ha almacenado correctamente la siguiente fecha de contacto"
  ) {
    LoadingPopUp();
    axios
      .post(
        ENDPOINTS.services.delayNextContactDate,
        dataJSON,
        this.#preparedConfig
      )
      .then(
        (result) => {
          PopupMessage("success", sucess_text, "none", redirect);
        },
        (error) => {
          PopupMessage("error", "Algo no va bien", "", redirect);
        }
      );
  }
}
