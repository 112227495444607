import React from "react";
import "./task.styles.scss";

function onButtonClick() {
  window.location = "/" + this.props.taskId + "/presupuestar/5";
}

function ButtonAssignedSendBudget(props) {
  return (
    <button
      className={props.className}
      onClick={() => {
        onButtonClick();
      }}
    >
      Solicitar pago adicional al cliente
    </button>
  );
}

export default ButtonAssignedSendBudget;
