import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import Header from "../../components/header/header.component";
import Footer from "../../components/footer/footer.component";
import PopupMessage from "../../components/popup-message/popup-message.component";
import CustomButton from "../../components/custom-button/custom-button.component";
import "./upload-invoice.styles.scss";
import PopupConfirm from "../../components/popup-message/popup-confirm.component";
import { DatePicker, Input } from "react-rainbow-components";
import ENDPOINTS from "../../utils/endpoints";
registerPlugin(FilePondPluginImagePreview);

class UploadInvoice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      taskId: this.props.match.params.taskId
        ? this.props.match.params.taskId
        : JSON.parse(localStorage.getItem("task")).taskId,
      userData: this.props.location.query
        ? this.props.location.query.userData
        : JSON.parse(localStorage.getItem("task")).userData,
      token: this.props.location.query
        ? this.props.location.query.token
        : JSON.parse(localStorage.getItem("task")).token,
      uploadToken: this.props.location.query
        ? this.props.location.query.uploadToken
        : JSON.parse(localStorage.getItem("task")).uploadToken,
      uploadedFiles: [],
      invoiceDate: new Date(),
      locale: { name: "es-ES", label: "Spanish (Spain)" },
      invoiceNumber: "",
    };
    this.handlePondFile = this.handlePondFile.bind(this);
  }

  handleSubmitClick(uploadedFiles, invoiceNumber, invoiceDate) {
    const urlCheckInvoiceNumber =
      ENDPOINTS.services.checkInvoiceNumber +
      this.state.invoiceNumber +
      "/" +
      this.state.taskId;

    const url = ENDPOINTS.services.document;

    const preparedConfig = {
      headers: {
        Authorization: "Bearer " + this.state.token,
        "content-type": "application/merge-patch+json",
      },
    };

    axios.get(urlCheckInvoiceNumber, preparedConfig).then(
      (result) => {
        console.log(urlCheckInvoiceNumber);
        console.log(result);
        if (result.data) {
          PopupMessage("error", "Ya has usado ese numero de factura", "");
        } else {
          let day = ("0" + this.state.invoiceDate.getDate()).slice(-2);

          let month = ("0" + (this.state.invoiceDate.getMonth() + 1)).slice(-2);

          let year = this.state.invoiceDate.getFullYear();

          axios
            .post(
              url,
              {
                MultihelpersJobCode: this.state.taskId,
                documents: uploadedFiles,
                source: "070B0B47-F36B-1410-8FBE-005CABA086B5",
                type: "160B0B47-F36B-1410-8FBE-005CABA086B5",
                invoiceNumber: invoiceNumber,
                invoiceDate: `${year}-${month}-${day}T00:00:00.000`,
              },
              preparedConfig
            )
            .then(
              (result) => {
                PopupMessage(
                  "success",
                  "Tu factura se ha subido correctamente",
                  this.state.taskId,
                  this.state.taskId + "/documentos"
                );
              },
              (error) => {
                PopupMessage("error", "Algo no va bien", "");
              }
            );
        }
      },
      (error) => {
        PopupMessage(
          "error",
          "Algo no va bien",
          "",
          this.state.thistaskId + "/calendario"
        );
      }
    );
  }

  sendInvoiceToAdminByEmail(urlSendInvoiceToAdminByEmail) {
    const preparedConfig = {
      headers: {
        Authorization: "Bearer " + this.state.token,
        accept: "application/json",
      },
    };
    axios.post(urlSendInvoiceToAdminByEmail, null, preparedConfig).then(
      (result) => {
        console.log("Exito send send alert");
      },
      (error) => {
        console.log("Error send send alert");
      }
    );
  }
  handleChange(value, e) {
    console.log(value);
    this.setState({ invoiceDate: value });
  }

  handleChangeInvoiceNumberChange = (event) => {
    console.log(this.state.invoiceNumber);
    this.setState({ invoiceNumber: event.target.value });
    console.log(this.state.invoiceNumber);
  };

  handlePondFile = (error, file) => {
    if (error) {
      console.log("Oh no");
      return;
    }

    let backedUrl = JSON.parse(file.serverId).url;
    this.setState({
      uploadedFiles: this.state.uploadedFiles.concat(backedUrl),
    });
  };

  handleBackAction = (e) => {
    e.preventDefault();
    PopupConfirm(
      "warning",
      "¿Estás seguro de que quieres salir? No has subido la factura",
      this.state.taskId,
      this.state.taskId + "/documentos"
    );
  };

  render() {
    let taskId = this.state.taskId;

    return (
      <div className="parent">
        <Header />

        <div className="upload-invoice">
          <h3 className="step-title">FACTURA</h3>
          <Link
            className="step-back"
            id="invoice-link"
            onClick={this.handleBackAction}
            to={{ pathname: "/" + taskId }}
          >
            <span>
              <FontAwesomeIcon icon={faArrowLeft} />
            </span>
          </Link>

          <div className="invoice-data-container">
            <div className="invoice-number-container">
              <div>Numero de Factura:</div>
              <Input onChange={this.handleChangeInvoiceNumberChange} />
            </div>
            <div className="invoice-date-container">
              <div>Fecha de facturacion:</div>
              <DatePicker
                value={this.state.invoiceDate}
                onChange={(value, e) => this.handleChange(value, e)}
                formatStyle="small"
                locale={this.state.locale.name}
              />
            </div>
          </div>

          <div className="file-uploader">
            <FilePond
              onprocessfile={this.handlePondFile}
              allowMultiple={false}
              imagePreviewMaxHeight={100}
              styleButtonRemoveItemPosition="left"
              styleButtonProcessItemPosition="bottom"
              styleLoadIndicatorPosition="right"
              styleProgressIndicatorPosition="right"
              instantUpload={true}
              checkValidity={true}
              dropValidation={true}
              allowRevert={true}
              name="document"
              maxFiles={1}
              server={{
                url:
                  process.env.REACT_APP_FILEUPLOADER_URL +
                  "documents/" +
                  this.state.taskId,
                process: {
                  method: "POST",
                  headers: {
                    Authorization: "Basic " + this.state.uploadToken,
                  },
                },
              }}
              labelFileProcessingComplete=" Subida completada"
              labelTapToCancel="Toca para cancelar"
              labelButtonAbortItemLoad="Cancelar descarga"
              labelButtonRemoveItem="Borrar archivo"
              labelButtonRetryItemLoad="Cargando"
              labelButtonRetryItemProcessing="Reintentar"
              labelButtonUndoItemProcessing="Deshacer"
              labelDecimalSeparator=","
              labelFileAdded="Archivo Añadido"
              labelFileCountPlural="Archivos"
              labelFileCountSingular="Archivo"
              labelFileLoadError="Error al subir"
              labelFileLoading="Cargando"
              labelFileProcessing="Subiendo"
              labelFileProcessingAborted="Subida cancelada"
              labelFileProcessingError="Error al subir archivo"
              labelFileRemoved="Archivo eliminado"
              labelFileSizeNotAvailable="Tamaño no disponible"
              labelFileWaitingForSize="Comprobando tamaño"
              labelIdle="<div class='custom-plus-div'>Agregar factura&nbsp;&nbsp;<span class='custom-plus-icon'></span></div>"
              labelTapToRetry="Toca para reintentar"
              labelTapToUndo="Toca para deshacer"
              labelThousandsSeparator=""
            />

            <CustomButton
              className={"custom-button "}
              disabled={!this.state.uploadedFiles.length}
              onClick={() =>
                this.handleSubmitClick(
                  this.state.uploadedFiles,
                  this.state.invoiceNumber,
                  this.state.invoiceDate
                )
              }
            >
              Subir
            </CustomButton>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default UploadInvoice;
