import Swal from "sweetalert2";

import './popup-message.styles.scss';

const PopupConfirmWithoutRedirect = async (icon, title,confirmText,CancelTextr,extraClass,funct) => {
  await Swal.fire({
    icon: icon,
    title: title,
    allowOutsideClick: false,
    showConfirmButton: true,
    showCancelButton: true,
    showCloseButton: false,
    confirmButtonText: confirmText,
    cancelButtonText: CancelTextr,
    customClass: extraClass
  }).then((result) => {
    funct(result);
  });
};

export default PopupConfirmWithoutRedirect;
