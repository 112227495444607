import React from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import Pagination from "react-paginating";
import LoadingSkeleton from "../loading-skeleton/loading-skeleton.component";
import "./available-tasks.styles.scss";
import moment from "moment";
import { GoStar } from "react-icons/go";
import PopupMessage from "../popup-message/popup-message.component";

// Constants
import ENDPOINTS from "../../utils/endpoints";

class AvailablejobsTasks extends React.Component {
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      serviceData: [],
      userData: this.props.location.search
        ? this.props.location.search
        : JSON.parse(localStorage.getItem("task")).userData,
      redirect: false,
      showFilters: false,
      filtersTitle: "Ver Filtros",
      idInputValue: "",
      filteredArray: [],
      selectedStageOption: "",
      stageOptions: [],
      selectedDateOption: "",
      dateOptions: [],
      selectedCategoryOption: "",
      categoryOptions: [],
      currentPage: 1,
      token: false,
      hasAccessToVisitScheduler: false,
      hasAccessToAvailableJobs: false,
      helperId: false,
    };

    localStorage.setItem("task", JSON.stringify(this.state));

    this.handleFilters = this.handleFilters.bind(this);
  }

  controller = new AbortController();

  handlePageChange = (page, e) => {
    this.setState({
      currentPage: page,
    });
  };

  decryptUserData() {
    const decryptedStr = this.handleHash(this.state.userData);
    const passwordArr = decryptedStr.split("password=");
    const pass = passwordArr[1];
    const usernameArr = passwordArr[0].split("username=");
    const user = usernameArr[1].slice(0, -1);

    return [user, pass];
  }

  handleHash(hash) {
    try {
      return atob(hash.substr(1));
    } catch (e) {
      this.setState({ redirect: true });
    }
  }

  loginCheckAPI(urlLoginCheckAPI, userObject) {
    axios.post(urlLoginCheckAPI, userObject).then(
      (result) => {
        this.setState({ token: result.data.token });

        const preparedConfig = {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
            accept: "application/json",
          },
        };

        const urlGetHelperId = ENDPOINTS.userMe;

        this.getHelperId(urlGetHelperId, preparedConfig);
      },
      (error) => {
        window.location.href = process.env.REACT_APP_WEB_LOGIN_URL;
      }
    );
  }

  getHelperId(urlGetHelperId, preparedConfig) {
    axios.get(urlGetHelperId, preparedConfig).then(
      (result) => {
        this.setState({
          helperId: result.data.UsersCode,
          hasAccessToVisitScheduler: result.data.hasAccessToVisitScheduler,
          hasAccessToAvailableJobs: result.data.hasAccessToAvailableJobs,
        });

        if (!result.data.helper_active) {
          this.setState({ redirect: true });
        }

        this.setState({
          userBasicToken: result.data.auth_content,
        });

        localStorage.setItem("taskList", JSON.stringify(this.state));

        let urlAvailableTasks;

        if (this.state.hasAccessToVisitScheduler) {
          if (this.state.hasAccessToAvailableJobs) {
            urlAvailableTasks = ENDPOINTS.services.avaibleAndVisitList;
          } else {
            urlAvailableTasks = ENDPOINTS.services.avaibleVisitList;
          }
        } else {
          if (this.state.hasAccessToAvailableJobs) {
            urlAvailableTasks = ENDPOINTS.services.avaibleServicesList;
          }
        }

        this.getHelperTasks(urlAvailableTasks, preparedConfig);
      },
      (error) => {
        this.setState({ redirect: true });
      }
    );
  }

  componentDidMount() {
    let secureArray = this.decryptUserData();
    const userObject = {
      username: secureArray[0],
      password: secureArray[1],
    };
    const urlLoginCheckAPI = ENDPOINTS.urlLoginCheckAPI;
    this.loginCheckAPI(urlLoginCheckAPI, userObject, secureArray);
  }

  componentWillUnmount() {
    this.signal.cancel("API is being canceled");
    this.controller.abort();
  }

  getHelperTasks(urlAvailableTasks, preparedConfig) {
    axios.get(urlAvailableTasks, preparedConfig).then(
      (result) => {
        if (result.data.length == 0) {
          this.ShowMessageNoTasksFoundAndRedirect();
        }

        this.setState({
          serviceData: result.data,
          filteredArray: this.paginatorNormalizer(result.data),
          stageOptions: this.stageOptionsNormalizer(result.data),
          categoryOptions: this.categoryOptionsNormalizer(result.data),
          dateOptions: this.dateOptionsNormalizer(result.data),
        });
      },
      (error) => {
        this.setState({ redirect: true });
      }
    );
  }

  // Show messages
  ShowMessageNoTasksFoundAndRedirect() {
    PopupMessage(
      "info",
      "No se han detectado servicios disponibles",
      "",
      "servicios"
    );
  }

  goToAvailableTask(taskId) {
    window.location = "/" + taskId + "/disponible";
  }

  goToTaskToScheduleVisit(taskId) {
    window.location = "/" + taskId + "/agendar_visita";
  }

  createContext = (serviceDataArray) => {
    let context = [];
    for (let taskObj of serviceDataArray) {
      if (
        taskObj.taskStage == "AGENDAR VISITA" &&
        taskObj.helperId == this.state.helperId
      ) {
        context.push(
          <div
            className="task-list-container-visit"
            key={taskObj.id}
            onClick={() => {
              this.goToTaskToScheduleVisit(taskObj.id);
            }}
          >
            <div className="task-list-header-visit">
              <div>{taskObj.id}</div>

              <div>{taskObj.categoryName}</div>

              <div>({taskObj.postalcode})</div>
            </div>
            <div className="task-list-content">
              <span class="task-list-description-star">
                <GoStar></GoStar>
              </span>
              <div className="helper-task-list-description">
                {taskObj.description}
              </div>
            </div>
          </div>
        );
      } else if (taskObj.taskStage == "AGENDAR VISITA") {
        context.push(
          <div
            className="task-list-container-visit"
            key={taskObj.id}
            onClick={() => {
              this.goToTaskToScheduleVisit(taskObj.id);
            }}
          >
            <div className="task-list-header-visit">
              <div>{taskObj.id}</div>

              <div>{taskObj.categoryName}</div>

              <div>({taskObj.postalcode})</div>
            </div>
            <div className="task-list-description">{taskObj.description}</div>
          </div>
        );
      } else if (taskObj.helperId) {
        context.push(
          <div
            className="task-list-container"
            key={taskObj.id}
            onClick={() => {
              this.goToAvailableTask(taskObj.id);
            }}
          >
            <div className="task-list-header">
              <div>{taskObj.id}</div>

              <div>{taskObj.categoryName}</div>

              <div>({taskObj.postalcode})</div>
            </div>
            <div className="task-list-content">
              <span class="task-list-description-star">
                <GoStar></GoStar>
              </span>
              <div className="helper-task-list-description">
                {taskObj.description}
              </div>
            </div>
          </div>
        );
      } else {
        context.push(
          <div
            className="task-list-container"
            key={taskObj.id}
            onClick={() => {
              this.goToAvailableTask(taskObj.id);
            }}
          >
            <div className="task-list-header">
              <div>{taskObj.id}</div>

              <div>{taskObj.categoryName}</div>

              <div>({taskObj.postalcode})</div>
            </div>
            <div className="task-list-description">{taskObj.description}</div>
          </div>
        );
      }
    }

    return context.reverse();
  };

  handleFilters = () => {
    const { showFilters } = this.state;
    this.setState({
      showFilters: !showFilters,
      filtersTitle: !this.state.showFilters ? "Ocultar Filtros" : "Ver Filtros",
    });
  };

  filterServiceId = (e) => {
    let currentList = [];
    let newList = [];

    if (e.target.value !== "") {
      let value = e.target.value;
      currentList = this.state.serviceData;

      newList = currentList.filter((item) => {
        return String(item.id).includes(value);
      });
    } else {
      newList = this.state.serviceData;
    }
    this.setState({
      idInputValue: e.target.value,
      filteredArray: this.paginatorNormalizer(newList),
    });
  };

  filterStage = (selectedStageOption) => {
    let currentList = [];
    let newList = [];

    if (selectedStageOption !== "") {
      currentList = this.state.serviceData;
      newList = currentList.filter((item) => {
        return item.taskStage === selectedStageOption.value;
      });
    }

    this.setState({
      filteredArray: this.paginatorNormalizer(newList),
      selectedStageOption: selectedStageOption,
    });
  };

  filterCategory = (selectedCategoryOption) => {
    let currentList = [];
    let newList = [];
    if (selectedCategoryOption !== "") {
      currentList = this.state.serviceData;
      newList = currentList.filter((item) => {
        return item.categoryName === selectedCategoryOption.value;
      });
    }

    this.setState({
      filteredArray: this.paginatorNormalizer(newList),
      selectedCategoryOption: selectedCategoryOption,
    });
  };

  filterByDate = (selectedDateOption) => {
    let currentList = [];
    let newList = [];

    if (selectedDateOption !== "") {
      currentList = this.state.serviceData;
      newList = currentList.filter((item) => {
        return item.executionDate === selectedDateOption.value;
      });
    }

    this.setState({
      filteredArray: this.paginatorNormalizer(newList),
      selectedDateOption: selectedDateOption,
    });
  };

  paginatorNormalizer = (serviceDataArr) => {
    let contextCopy = this.createContext(serviceDataArr);
    let paginatedContext = [];
    while (contextCopy.length > 0) {
      // TODO change delete count for the number of tasks per page
      let a = contextCopy.splice(0, 10);
      paginatedContext.push(a);
    }
    return paginatedContext;
  };

  stageOptionsNormalizer = (serviceDataArr) => {
    let resultArr = [];

    for (let elem of serviceDataArr) {
      if (elem.taskStage == "EMAIL CONFIRMACION") {
        resultArr.push({
          value: elem.taskStage,
          label: "Servicio presupuestado",
        });
      } else if (elem.taskStage == "AGENDAR VISITA") {
        resultArr.push({
          value: elem.taskStage,
          label: "Pendiente de presupuesto",
        });
      }
    }
    resultArr = this.removeArrDuplicates(resultArr, "value");

    return resultArr;
  };

  categoryOptionsNormalizer = (serviceDataArr) => {
    let resultArr = [];

    for (let elem of serviceDataArr) {
      resultArr.push({ value: elem.categoryName, label: elem.categoryName });
    }
    resultArr = this.removeArrDuplicates(resultArr, "value");

    return resultArr;
  };

  dateOptionsNormalizer = (serviceDataArr) => {
    let resultArray = [];
    for (let element of serviceDataArr) {
      resultArray.push({
        value: element.executionDate,
        label: element.executionDate,
      });
    }
    resultArray = this.removeArrDuplicates(resultArray, "value");

    return resultArray;
  };

  removeArrDuplicates = (originalArray, prop) => {
    let newArray = [];
    let lookupObject = {};

    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }

    return newArray;
  };

  resetFilters = () => {
    this.setState({
      idInputValue: "",
      selectedStageOption: "",
      selectedDateOption: "",
      selectedCategoryOption: "",
      filteredArray: this.paginatorNormalizer(this.state.serviceData),
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={{ pathname: "/" }} />;
    }

    if (
      typeof this.state.serviceData === "undefined" ||
      this.state.serviceData.length === 0
    ) {
      return <LoadingSkeleton />;
    }

    const limit = 2;
    const pageCount = 3;
    const {
      currentPage,
      showFilters,
      selectedStageOption,
      selectedDateOption,
      selectedCategoryOption,
    } = this.state;
    const total = this.state.filteredArray.length * limit;

    return (
      <div>
        <span className="task-filter" onClick={this.handleFilters}>
          {this.state.filtersTitle}
        </span>
        {showFilters && (
          <div className="filter-section">
            <div className="filter-by-id">
              <label htmlFor="filterById">ID servicio:&nbsp;</label>
              <input
                type="tel"
                pattern="^-?[0-9]\d*\.?\d*$"
                id="filterById"
                value={this.state.idInputValue}
                onChange={this.filterServiceId}
              />
            </div>
            <div className="filter-by-category">
              <label>Categoría:&nbsp;</label>
              <Select
                className="category-select-container"
                classNamePrefix="category-select"
                placeholder=""
                value={selectedCategoryOption}
                onChange={this.filterCategory}
                options={this.state.categoryOptions}
              />
            </div>
            <div className="filter-by-stage">
              <label>Tipo Servicio:&nbsp;</label>
              <Select
                className="stage-select-container"
                classNamePrefix="stage-select"
                placeholder=""
                value={selectedStageOption}
                onChange={this.filterStage}
                options={this.state.stageOptions}
              />
            </div>

            <div className="reset-filters" onClick={this.resetFilters}>
              <span>Reestablecer filtros</span>
            </div>
          </div>
        )}
        <div className="task-list-wrapp">
          {this.state.filteredArray[this.state.currentPage - 1]}
        </div>

        <Pagination
          className="bg-mh"
          total={total}
          limit={limit}
          pageCount={pageCount}
          currentPage={currentPage}
        >
          {({
            pages,
            currentPage,
            hasNextPage,
            hasPreviousPage,
            previousPage,
            nextPage,
            totalPages,
            getPageItemProps,
          }) => (
            <div>
              {hasPreviousPage && (
                <button
                  className="paginating-button"
                  {...getPageItemProps({
                    pageValue: previousPage,
                    onPageChange: this.handlePageChange,
                  })}
                >
                  {"<"}
                </button>
              )}

              {pages.map((page) => {
                let activePage = null;
                if (currentPage === page) {
                  activePage = { backgroundColor: "#00cded", color: "#fff" };
                }
                if (this.state.filteredArray.length > 1) {
                  return (
                    <button
                      className="paginating-button"
                      {...getPageItemProps({
                        pageValue: page,
                        key: page,
                        style: activePage,
                        onPageChange: this.handlePageChange,
                      })}
                    >
                      {page}
                    </button>
                  );
                }
              })}

              {hasNextPage && (
                <button
                  className="paginating-button"
                  {...getPageItemProps({
                    pageValue: nextPage,
                    onPageChange: this.handlePageChange,
                  })}
                >
                  {">"}
                </button>
              )}
            </div>
          )}
        </Pagination>
      </div>
    );
  }
}

export default AvailablejobsTasks;
