import React from "react";
import axios from "axios";
import moment from "moment";
import Modal from "react-modal";

import LoadingSkeleton from "../loading-skeleton/loading-skeleton.component";
import DayEvent from "./dayevent.component";
import MonthEvents from "./monthevents.components";
import WeekEvents from "./weekevents.components";
import PopupMessage from "../../components/popup-message/popup-message.component";
import PopupWithSelectBox from "../../components/popup-message/popup-with-select-box.component";
import GenericAsyncPopupComponent from "../../components/popup-message/generic-async-popup.component";

import { Calendar, momentLocalizer } from "react-big-calendar";
import { Redirect, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

// Constants
import ENDPOINTS from "../../utils/endpoints";

import "./accept-change-date-calendar.styles.scss";

class taskcalendar extends React.Component {
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);

    this.state = {
      serviceData: [],
      visitsData: [],
      tasksData: [],
      thistaskId: this.props.match.params.taskId,
      userData: this.props.location.search
        ? this.props.location.search
        : JSON.parse(localStorage.getItem("task")).userData,
      redirect: false,
      showFilters: false,
      filtersTitle: "Ver Filtros",
      idInputValue: "",
      filteredArray: [],
      myEvents: [],
      selectedStageOption: "",
      stageOptions: [],
      selectedDateOption: "",
      dateOptions: [],
      currentPage: 1,
      token: false,
      helperId: false,
      thisTask: [],
      contador: 0,
      executionDateOption1: "",
      executionDateOption2: "",
      executionDateOption3: "",
      showModal: false,
      inactiveDays: [
        "12-10-2021",
        "01-11-2021",
        "06-12-2021",
        "08-12-2021",
        "25-12-2021",
        "01-01-2022",
        "06-01-2022",
        "12-10-2022",
        "01-11-2022",
        "06-12-2022",
        "08-12-2022",
        "05-12-2022",
      ],
      showModalInicio: true,
      reason: "",
      coming_from_asign_visit: false,
      changing_contact_date: false,
      selectedDate: null,
    };

    if (this.props.match.params.comingFromAsignVisit == "1") {
      this.state.coming_from_asign_visit = true;
    }

    if (this.props.match.params.comingFromAsignVisit == "2") {
      this.state.changing_contact_date = true;
    }

    if (this.props.match.params.askReason == "1") {
      var reasons = {
        "No disponibilidad cliente": "No disponibilidad cliente",
        "No disponibilidad profesional": "No disponibilidad profesional",
        "Hay que hacer visita adicional": "Hay que hacer visita adicional",
        Materiales: "Materiales",
        Temporal: "Temporal",
      };

      if (this.state.changing_contact_date) {
        reasons = {
          1: "Cliente no contesta",
          2: "Cliente solicita llamada otro día",
          3: "Cliente de vacaciones",
          4: "Reparador falta de agenda",
          5: "Multihelpers falta de información",
          6: "Otros",
        };
      }

      PopupWithSelectBox(
        "question",
        "Especifique el motivo del cambio",
        this.props.match.params.taskId,
        "/" + this.props.match.params.taskId,
        "Confirmar",
        "Cancelar",
        reasons,
        "Seleccione una opción",
        (reason) => {
          this.state.reason = reason;
        }
      );
    } else if (this.props.match.params.askReason == "2") {
      this.state.reason = parseInt(localStorage.getItem("reason"));
      localStorage.removeItem("reason");
      GenericAsyncPopupComponent(
        "info",
        "A continuación seleccione la fecha y hora para aplazar el contacto",
        true,
        false,
        true,
        "Proceder",
        "",
        "mh-modal",
        () => {}
      );
    }

    if (this.props.match.params.comingFromAsignVisit == "1") {
      this.state.coming_from_asign_visit = true;
    }

    localStorage.setItem("task", JSON.stringify(this.state));
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModalInicio = this.handleCloseModalInicio.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  controller = new AbortController();

  handlePageChange = (page, e) => {
    this.setState({
      currentPage: page,
    });
  };

  handleOpenModal() {
    setTimeout(() => {
      this.setState({ showModal: true });
    }, 400);
  }

  handleCloseModalInicio() {
    this.setState({ showModalInicio: false });
  }

  handleCloseModal() {
    this.setState({ showModal: false });

    const preparedConfig = {
      headers: {
        Authorization: "Bearer " + this.state.token,
        accept: "application/json",
      },
    };

    // devuelve o el id (en mi caso el código) del helper o falso
    const urlGetJobsHelperId =
      ENDPOINTS.services.helperAssigned + this.state.thistaskId;

    axios.get(urlGetJobsHelperId, preparedConfig).then(
      (result) => {
        const actualHelper = result.data;

        if (!actualHelper || actualHelper == this.state.helperId.toString()) {
          const url = ENDPOINTS.services.update;

          let dataJSON = {
            MultihelpersJobCode: this.state.thistaskId,
            executionDateOption1: moment(this.state.selectedDate).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            executionDate: moment(this.state.selectedDate).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            executionHour: moment(this.state.selectedDate).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            executiondateselected: "1",
            isResolutionDataSentToHelper: true,
            helperUser: this.state.helperId.toString(),
            helperAsignedAutomatically: true,
            taskStage: "550B0B47-F36B-1410-8FBE-005CABA086B5",
            isResolutionDataSentToAdvertiser: true,
            isHelperResolutionDataToHelperSent: true,
            isClientResolutionDataToAdvertiserSent: true,
          };

          if (this.state.coming_from_asign_visit) {
            dataJSON.taskStage = "7D0B0B47-F36B-1410-8FBE-005CABA086B5";
          }

          if (!this.state.changing_contact_date) {
            // actualización de datos

            axios.post(url, dataJSON, preparedConfig).then(
              (result) => {
                if (!this.state.coming_from_asign_visit) {
                  const urlSendExecutionDateChangedToClient =
                    ENDPOINTS.zenvia.send;

                  const urlSendExecutionDateChangedToMH =
                    ENDPOINTS.mail.helperChangeExecutionDatePost;

                  // Manda mensajes
                  var sendExecutionDateChangedToClient = axios.post(
                    urlSendExecutionDateChangedToClient,
                    {
                      MultihelpersJobCode: this.state.thistaskId,
                      ZenviaTemplateUid: "2274E459-D9F7-47D8-A0CD-F9B650B271A0",
                    },
                    preparedConfig
                  );

                  var SendExecutionDateChangedToMH = axios.post(
                    urlSendExecutionDateChangedToMH,
                    {
                      MultihelpersJobCode: this.state.thistaskId,
                      Reason: this.state.reason,
                    },
                    preparedConfig
                  );

                  axios
                    .all([
                      sendExecutionDateChangedToClient,
                      SendExecutionDateChangedToMH,
                    ])
                    .then(
                      axios.spread((...responses) => {
                        PopupMessage(
                          "success",
                          "Se ha cambiado la cita correctamente",
                          "none",
                          "servicios_asignados"
                        );
                      })
                    )
                    .catch((errors) => {
                      PopupMessage(
                        "error",
                        "Algo no va bien",
                        "",
                        this.state.thistaskId + "/calendario"
                      );
                    });
                } else {
                  // Establecimiento brico
                  const urlSendConfirmationDateSet =
                    ENDPOINTS.mail.dateSetBricoAdministrationReminder;

                  var sendConfirmationDateSet = axios.post(
                    urlSendConfirmationDateSet,
                    { MultihelpersJobCode: this.state.thistaskId },
                    preparedConfig
                  );

                  axios
                    .all([sendConfirmationDateSet])
                    .then(
                      axios.spread((...responses) => {
                        PopupMessage(
                          "success",
                          "Se ha establecido la fecha correctamente",
                          "none",
                          "servicios_asignados"
                        );
                      })
                    )
                    .catch((errors) => {
                      PopupMessage(
                        "error",
                        "Algo no va bien",
                        "",
                        this.state.thistaskId + "/aceptar_rechazar_servicio"
                      );
                    });
                }
              },
              (error) => {
                PopupMessage(
                  "error",
                  "Algo no va bien",
                  "",
                  this.state.thistaskId + "/calendario"
                );
              }
            );
          } else {
            // Retrasar la fecha
            let url2 = ENDPOINTS.services.delayNextContactDate;

            dataJSON = {
              MultihelpersJobCode: this.state.thistaskId,
              reason: this.state.reason,
              date: moment(this.state.selectedDate).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
            };

            axios.post(url2, dataJSON, preparedConfig).then(
              (result) => {
                PopupMessage(
                  "success",
                  "Se ha almacenado correctamente la siguiente fecha de contacto",
                  "none",
                  "calendario"
                );
              },
              (error) => {
                PopupMessage("error", "Algo no va bien", "", "calendario");
              }
            );
          }
        } else {
          PopupMessage(
            "error",
            "No puedes aceptar este servicio porque ya ha sido aceptado por otro Helper",
            "",
            "/servicios"
          );
        }
      },
      (error) => {
        PopupMessage("error", "Algo a salido mal", "", "/calendario");
      }
    );

    console.log("Llego");
  }

  decryptUserData() {
    const decryptedStr = this.handleHash(this.state.userData);
    const passwordArr = decryptedStr.split("password=");
    const pass = passwordArr[1];
    const usernameArr = passwordArr[0].split("username=");
    const user = usernameArr[1].slice(0, -1);

    return [user, pass];
  }

  goBack(taskId) {
    if (
      this.state.coming_from_asign_visit ||
      this.state.changing_contact_date
    ) {
      window.location = "/" + taskId + "/aceptar_rechazar_servicio";
    } else {
      window.location = "/" + taskId;
    }
  }

  dateFormat(date) {
    return date
      ? moment(date, "DD-MM-YYYY HH:mm:ss").format("dddd D MMM HH.mm[h]")
      : "-";
  }

  handleHash(hash) {
    try {
      return atob(hash.substr(1));
    } catch (e) {
      this.setState({ redirect: true });
    }
  }

  loginCheckAPI(urlLoginCheckAPI, userObject) {
    axios.post(urlLoginCheckAPI, userObject).then(
      (result) => {
        this.setState({ token: result.data.token });

        const preparedConfig = {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
            accept: "application/json",
          },
        };

        const urlGetHelperId = ENDPOINTS.userMe;
        this.getHelperId(urlGetHelperId, preparedConfig);
      },
      (error) => {
        window.location.href = process.env.REACT_APP_WEB_LOGIN_URL;
      }
    );
  }

  getHelperId(urlGetHelperId, preparedConfig) {
    axios.get(urlGetHelperId, preparedConfig).then(
      (result) => {
        this.setState({
          helperId: result.data.UsersCode,
        });

        if (!result.data.helper_active) {
          this.setState({ redirect: true });
        }

        this.setState({
          userBasicToken: result.data.auth_content,
        });

        localStorage.setItem("taskList", JSON.stringify(this.state));

        const urlGetHelperTasks = ENDPOINTS.services.list;

        this.getHelperTasks(urlGetHelperTasks, preparedConfig);
      },
      (error) => {
        this.setState({ redirect: true });
      }
    );
  }

  componentDidMount() {
    let secureArray = this.decryptUserData();

    const userObject = {
      username: secureArray[0],
      password: secureArray[1],
    };
    const urlLoginCheckAPI = ENDPOINTS.urlLoginCheckAPI;
    this.loginCheckAPI(urlLoginCheckAPI, userObject, secureArray);
  }

  componentWillUnmount() {
    this.signal.cancel("API is being canceled");
    this.controller.abort();
  }

  getHelperTasks(urlGetHelperTasks, preparedConfig) {
    axios.get(urlGetHelperTasks, preparedConfig).then((result) => {
      this.setState({
        serviceData: result.data,
      });

      let taskList = [];

      this.state.serviceData.forEach((taskObj) => {
        if (!taskObj.addressObsevations) {
          taskObj.addressObsevations = "";
        }
        if (
          taskObj.isResolutionDataSentToHelper ||
          taskObj.isResolutionDataSentToAdvertiser
        ) {
          taskList.push({
            id: taskObj.id,
            isNew: false,
            title: "" + taskObj.id,
            allDay: false,
            isVisit: false,
            start: new Date(taskObj.executionDatetimeUS),
            end: new Date(taskObj.executionDatetimeUS),
            daylabel: "ID " + taskObj.id + " " + taskObj.executionDatetime,
            popaplabeldate: taskObj.executionDatetime,
            location:
              taskObj.addressStreet +
              " " +
              taskObj.addressNumber +
              " " +
              taskObj.addressObsevations +
              "(" +
              taskObj.postalcode +
              ")",
            perfilHelperStage: taskObj.perfilHelperStage,
          });
        }
      });
      this.setState({ tasksData: taskList });

      const urlGetHelperTasks =
        ENDPOINTS.services.serviceData + this.state.thistaskId;

      const urlGetHelperVisits = ENDPOINTS.visits.list;

      this.getHelpervisits(urlGetHelperVisits, preparedConfig);
      this.getavaliableTask(urlGetHelperTasks, preparedConfig);
    });
  }

  getHelpervisits(urlGetHelperVisits, preparedConfig) {
    axios
      .get(urlGetHelperVisits, preparedConfig)
      .then((result) => {
        this.helperVisitsHandleResponse(result);
      })
      .catch((error) => {
        if (error?.response?.status == 404) {
          this.helperVisitsHandleResponse();
        }
      });
  }

  helperVisitsHandleResponse(result) {
    let visitList = [];
    for (let visitObj of result?.data || []) {
      if (!visitObj.addressObsevations) {
        visitObj.addressObsevations = "";
      }
      if (visitObj.isVisitDataSentToHelper)
        visitList.push({
          id: visitObj.id,
          isVisit: true,
          title: "" + visitObj.id,
          allDay: false,
          start: new Date(visitObj.visitDatetimeUS + " " + visitObj.visitTime),
          end: new Date(visitObj.visitDatetimeUS + " " + visitObj.visitTime),
          daylabel:
            "VISITA ID " +
            visitObj.id +
            " " +
            visitObj.visitDate +
            " " +
            visitObj.visitTime,
          popaplabeldate: visitObj.visitDatetimeUS + " " + visitObj.visitTime,
          location:
            visitObj.addressStreet +
            " " +
            visitObj.addressNumber +
            " " +
            visitObj.addressObsevations +
            "(" +
            visitObj.postalcode +
            ")",
          perfilHelperStage: visitObj.perfilHelperStage,
        });
    }
    this.setState({
      visitsData: visitList,
    });
    this.state.visitsData.forEach((visit) => {
      this.state.tasksData.push(visit);
    });
    this.setState({ myEvents: this.state.tasksData });
  }

  getavaliableTask(urlGetHelperTasks, preparedConfig) {
    axios.get(urlGetHelperTasks, preparedConfig).then(
      (result) => {
        this.setState({
          thisTask: result.data,
          executionDateOption2: result.data.executionDateOption2,
          executionDateOption3: result.data.executionDateOption3,
        });
      },
      (error) => {
        this.setState({ redirect: true });
      }
    );
  }

  removeArrDuplicates = (originalArray, prop) => {
    let newArray = [];
    let lookupObject = {};

    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }

    return newArray;
  };

  handleSlot = (date) => {
    let unixDate = moment(date).format("x");
    if (this.state.inactiveDays.includes(moment(date).format("DD-MM-YYYY"))) {
      return { className: "calendar-slot-lock " + unixDate };
    } else if (
      moment(date).isAfter() &&
      moment(date).format("H") >= 7 &&
      moment(date).format("H") <= 22
    ) {
      return { className: "calendar-slot-available " + unixDate };
    }
    return { className: "calendar-slot-lock " + unixDate };
  };

  navigate = () => {
    const slots = document.getElementsByClassName(
      "rbc-time-slot calendar-slot-available"
    );
    const fnHandleSelect = this.handleSelect;
    setTimeout(() => {
      for (let i = 0; i < slots.length; i++) {
        const el = slots[i];
        let onClickSlot = (e) => {
          let element = e.target;
          if (element.className == "rbc-label")
            element = e.target.parentElement;
          // let time = moment.utc(moment.duration((i-4) / 2, 'hours').asMilliseconds()).format('HH:mm:ss');
          let time = element.classList[element.classList.length - 1];
          let dateObj = moment(time, "x").toDate();
          fnHandleSelect(dateObj);
        };
        el.onclick = onClickSlot;
      }
    });
  };

  handleSelect = (date) => {
    if (
      !this.state.inactiveDays.includes(moment(date).format("DD-MM-YYYY")) &&
      moment(date).isAfter() &&
      moment(date).format("H") >= 7 &&
      moment(date).format("H") <= 22
    ) {
      const title = "+";
      this.setState({
        myEvents: [
          ...this.state.myEvents,
          {
            start: date,
            end: date,
            title,
            isNew: true,
          },
        ],
      });

      this.state.executionDateOption1 = moment(date).format(
        "DD-MM-YYYY HH:mm:ss"
      );
      this.state.selectedDate = date;
      this.handleOpenModal();
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={{ pathname: "/" }} />;
    }

    if (typeof this.state.serviceData === "undefined") {
      return <LoadingSkeleton />;
    }

    const MonthEvent = ({ event }) => (
      <div>
        <div>{event.title}</div>
      </div>
    );

    require("moment/locale/es.js");
    const mylocalizer = momentLocalizer(moment);

    var back_url = "/" + this.state.thistaskId;
    if (this.state.coming_from_asign_visit) {
      back_url = "/" + this.state.thistaskId + "/aceptar_rechazar_servicio";
    }

    const posponerContacto = this.state.changing_contact_date;

    // to enable click on calendar slots
    this.navigate();

    return (
      <div>
        <Link
          className="goBack"
          style={{ top: "5rem" }}
          to={{
            pathname: back_url,
            token: this.state.token,
            helperId: this.state.helperId,
          }}
        >
          <span>
            <FontAwesomeIcon icon={faArrowLeft} />
          </span>
        </Link>

        <Calendar
          localizer={mylocalizer}
          events={this.state.myEvents}
          startAccessor="start"
          defaultView="week"
          timeslots={1}
          onView={this.navigate}
          longPressThreshold={250000000}
          views={{ month: true, week: true, day: true }}
          // To show only hours between 7 and 22, year and month is not important
          min={new Date(2017, 10, 0, 7, 0, 0)}
          max={new Date(2017, 10, 0, 22, 0, 0)}
          drilldownView="day"
          onNavigate={this.navigate}
          onSelectEvent={this.handleEventClick}
          onSelectSlot={this.handleSelect}
          slotPropGetter={this.handleSlot}
          eventPropGetter={(event, start, end, isSelected) => {
            let newStyle = {
              backgroundColor: "rgb(0, 205, 237)",
              color: "white",
            };

            if (event.perfilHelperStage == "Pendiente de pago") {
              newStyle.backgroundColor = "#ffbd59";
              newStyle.color = "white";
            } else if (event.perfilHelperStage == "Finalizado") {
              newStyle.backgroundColor = "#8cb70d";
              newStyle.color = "white";
            } else {
              newStyle.backgroundColor = "#00cded";
              newStyle.color = "white";
            }

            if (!event.id) {
              newStyle.backgroundColor = "#8bc34a";
              newStyle.color = "white";
            }

            return {
              className: "",
              style: newStyle,
            };
          }}
          popup={true}
          messages={{
            next: "sig",
            previous: "ant",
            today: "Hoy",
            month: "Mes",
            week: "Semana",
            day: "Día",
            showMore: (total) => (
              <div
                style={{ cursor: "pointer" }}
                onMouseOver={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                {`+${total} más`}
              </div>
            ),
          }}
          components={{
            day: { event: DayEvent },
            month: { event: MonthEvents },
            week: { event: WeekEvents },
          }}
        />
        <Modal
          ariaHideApp={false}
          className="modal-init"
          isOpen={this.state.showModal}
          contentLabel="Minimal Modal Example"
        >
          <span>
            <FontAwesomeIcon
              className="icon-back"
              icon={faArrowLeft}
              onClick={() => {
                this.goBack(this.state.thistaskId);
              }}
            />
          </span>

          {!posponerContacto && (
            <div className="modal-propouse-text">
              Estas a punto de proponer al cliente la siguientes fechas para
              realizar el servicio{" "}
              <span className="bold">{this.state.thistaskId}</span>:
            </div>
          )}

          {posponerContacto && (
            <div className="modal-propouse-text">
              Estas a punto de posponer la fecha de contacto del servicio{" "}
              <span className="bold">{this.state.thistaskId}</span>:
            </div>
          )}

          <div className="modal-propouse-dates">
            <div>- {this.dateFormat(this.state.executionDateOption1)}</div>
          </div>
          <button
            className="custom-button modal-button-documentos"
            onClick={this.handleCloseModal}
          >
            Aceptar
          </button>
        </Modal>
      </div>
    );
  }
}

export default taskcalendar;
