import React from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import Pagination from "react-paginating";
import LoadingSkeleton from "../../loading-skeleton/loading-skeleton.component";
import { Row, Col, Container } from "react-bootstrap";

// Constants
import ENDPOINTS from "../../../utils/endpoints";

class InvoiceListComponent extends React.Component {
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);

    if (
      this.props.location.search == "" &&
      JSON.parse(localStorage.getItem("invoice"))?.userData == null
    ) {
      window.location.href = process.env.REACT_APP_WEB_LOGIN_URL;
    }

    this.state = {
      serviceData: [],
      userData: this.props.location.search
        ? this.props.location.search
        : JSON.parse(localStorage.getItem("task")).userData,
      redirect: false,
      showFilters: false,
      filtersTitle: "Ver Filtros",
      idInputValue: "",
      filteredArray: [],
      currentPage: 1,
      token: false,
      helperId: false,
    };

    localStorage.setItem("invoice", JSON.stringify(this.state));

    this.handleFilters = this.handleFilters.bind(this);
  }

  controller = new AbortController();

  handlePageChange = (page, e) => {
    this.setState({
      currentPage: page,
    });
  };

  decryptUserData() {
    const decryptedStr = this.handleHash(this.state.userData);
    const passwordArr = decryptedStr.split("password=");
    const pass = passwordArr[1];
    const usernameArr = passwordArr[0].split("username=");
    const user = usernameArr[1].slice(0, -1);

    return [user, pass];
  }

  handleHash(hash) {
    try {
      return atob(hash.substr(1));
    } catch (e) {
      console.log("Error en Handle hash");
      this.setState({ redirect: true });
    }
  }

  loginCheckAPI(urlLoginCheckAPI, userObject) {
    axios.post(urlLoginCheckAPI, userObject).then(
      (result) => {
        this.setState({ token: result.data.token });

        const preparedConfig = {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
            accept: "application/json",
          },
        };

        this.getHelperId(ENDPOINTS.userMe, preparedConfig);
      },
      (error) => {
        window.location.href = process.env.REACT_APP_WEB_LOGIN_URL;
      }
    );
  }

  getHelperId(urlGetHelperId, preparedConfig) {
    axios.get(urlGetHelperId, preparedConfig).then(
      (result) => {
        this.setState({
          helperId: result.data.UsersUid,
        });

        if (!result.data.helper_active) {
          this.setState({ redirect: true });
        }

        localStorage.setItem("invoiceList", JSON.stringify(this.state));

        const urlGetHelperInvoices = ENDPOINTS.services.invoice_list;

        this.getHelperInvoices(urlGetHelperInvoices, preparedConfig);
      },
      (error) => {
        console.log("Error en get helper api");
        this.setState({ redirect: true });
      }
    );
  }

  componentDidMount() {
    let secureArray = this.decryptUserData();
    const userObject = {
      username: secureArray[0],
      password: secureArray[1],
    };
    const urlLoginCheckAPI = ENDPOINTS.urlLoginCheckAPI;

    this.loginCheckAPI(urlLoginCheckAPI, userObject, secureArray);
  }

  componentWillUnmount() {
    this.signal.cancel("API is being canceled");
    this.controller.abort();
  }

  getHelperInvoices(urlGetHelperInvoices, preparedConfig) {
    axios.get(urlGetHelperInvoices + '/' + this.state.helperId, preparedConfig).then(
      (result) => {
        this.setState({
          serviceData: result.data.data,
          filteredArray: this.paginatorNormalizer(result.data.data),
        });
      },
      (error) => {
        console.log("Error en get helper incoices");
        this.setState({ redirect: true });
      }
    );
  }

  createContext = (serviceDataArray) => {
    let context = [];
    for (let invoiceObj of serviceDataArray) {
      context.push(
        <div
          className="task-list-row"
          key={invoiceObj.MultihelpersJobCode}
          onClick={() => {
            //this.goToTask(invoiceObj.MultihelpersJobCode);
          }}
        >
          <Container className="container-row">
            <Row>
              <Col sm={12} md={true} className="first-col">
                <a href={invoiceObj.url} target="_blank">{invoiceObj.number}</a>
              </Col>
              <Col sm={12} md={true} className="default-col">
                {invoiceObj.date}
              </Col>
            </Row>
          </Container>
        </div>
      );
    }

    return context;
  };

  handleFilters = () => {
    const { showFilters } = this.state;
    this.setState({
      showFilters: !showFilters,
      filtersTitle: !this.state.showFilters ? "Ocultar Filtros" : "Ver Filtros",
    });
  };

  paginatorNormalizer = (serviceDataArr) => {
    let contextCopy = this.createContext(serviceDataArr);
    let paginatedContext = [];
    while (contextCopy.length > 0) {
      // TODO change delete count for the number of tasks per page
      let a = contextCopy.splice(0, 8);
      paginatedContext.push(a);
    }
    return paginatedContext;
  };

  resetFilters = () => {
    this.setState({
      idInputValue: "",
      filteredArray: this.paginatorNormalizer(this.state.serviceData),
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={{ pathname: "/" }} />;
    }

    if (
      typeof this.state.serviceData === "undefined" ||
      this.state.serviceData.length === 0
    ) {
      return <LoadingSkeleton />;
    }

    const limit = 2;
    const pageCount = 3;
    const {
      currentPage,
      showFilters,
    } = this.state;
    const total = this.state.filteredArray.length * limit;

    return (
      <div>
        <div>
          <div className="task-list-row-header ">
            <Container className="container-row">
              <Row>
                <Col sm={12} md={true} className="header-col d-none d-md-block">
                  Nombre
                </Col>
                <Col sm={12} md={true} className="header-col d-none d-md-block">
                  Fecha
                </Col>
              </Row>
            </Container>
          </div>
          {this.state.filteredArray[this.state.currentPage - 1]}
        </div>

        <Pagination
          className="bg-mh"
          total={total}
          limit={limit}
          pageCount={pageCount}
          currentPage={currentPage}
        >
          {({
            pages,
            currentPage,
            hasNextPage,
            hasPreviousPage,
            previousPage,
            nextPage,
            totalPages,
            getPageItemProps,
          }) => (
            <div>
              {hasPreviousPage && (
                <button
                  className="paginating-button"
                  {...getPageItemProps({
                    pageValue: previousPage,
                    onPageChange: this.handlePageChange,
                  })}
                >
                  {"<"}
                </button>
              )}

              {pages.map((page) => {
                let activePage = null;
                if (currentPage === page) {
                  activePage = { backgroundColor: "#00cded", color: "#fff" };
                }
                if (this.state.filteredArray.length > 1) {
                  return (
                    <button
                      className="paginating-button"
                      {...getPageItemProps({
                        pageValue: page,
                        key: page,
                        style: activePage,
                        onPageChange: this.handlePageChange,
                      })}
                    >
                      {page}
                    </button>
                  );
                }
              })}

              {hasNextPage && (
                <button
                  className="paginating-button"
                  {...getPageItemProps({
                    pageValue: nextPage,
                    onPageChange: this.handlePageChange,
                  })}
                >
                  {">"}
                </button>
              )}
            </div>
          )}
        </Pagination>
      </div>
    );
  }
}

export default InvoiceListComponent;
