import Swal from "sweetalert2";
import "./popup-message.styles.scss";
import logo from "../../assets/images/logo.png";
import "./loading.style.scss";

const LoadingPopUp = (icon, title, taskId, route) => {
  Swal.fire({
    html:
      `<div class='img-container'><div class='logo-loading'><img class='box bounce-7' src="` +
      logo +
      `" /></div><div class='text'>En proceso... </br> Espere por favor.</div></div>`,
    showCloseButton: false,
    showCancelButton: false,
    showConfirmButton: false,
    allowOutsideClick: false,
  });
};

export default LoadingPopUp;
