import React from "react";
import axios from "axios";
import ENDPOINTS from "../../utils/endpoints";
import GenericPopupWithSelectBox from "../popup-message/generic-popup-with-select-box.component";
import LoadingPopUp from "../popup-message/loading-popup.component";
import PopupMessage from "../popup-message/popup-message.component";

async function askForAReason(preparedConfig, is_brico, MultihelpersJobCode) {
  // First, we need to get all reasons

  let reasons = await axios.get(
    ENDPOINTS.services.RejectReasons,
    preparedConfig
  );

  if (reasons) {
    let showReasons = {};

    reasons.data.forEach((reason) => {
      if (is_brico && reason.HelperRejectMultihelpesJobReasonIsBrico == "1") {
        showReasons[reason.HelperRejectMultihelpesJobReasonUid] =
          reason.HelperRejectMultihelpesJobReasonName;
      } else if (
        !is_brico &&
        reason.HelperRejectMultihelpesJobReasonIsBrico == "0"
      ) {
        showReasons[reason.HelperRejectMultihelpesJobReasonUid] =
          reason.HelperRejectMultihelpesJobReasonName;
      }
    });

    GenericPopupWithSelectBox(
      "question",
      "Indica el motivo del rechazo ",
      "Confirmar",
      "Cancelar",
      showReasons,
      "Seleccione una opción",
      "mh-modal3",
      (response) => {
        if (response.isConfirmed && response.value) {
          rejectService(response.value, preparedConfig, MultihelpersJobCode);
        }
      }
    );
  }
}

function rejectService(ReasonUid, preparedConfig, JobCode) {
  LoadingPopUp();
  axios
    .post(
      ENDPOINTS.services.rejectService,
      {
        MultihelpersJobCode: JobCode,
        HelperRejectMultihelpesJobReasonUid: ReasonUid,
      },
      preparedConfig
    )
    .then((response) => {
      if (response.status == 202) {
        PopupMessage(
          "success",
          "El servicio ha sido rechazado correctamente",
          "none",
          "servicios"
        );
      }
    });
}

export default function ButtonAssignedSendBudget(props) {
  return (
    <button
      className={props.className}
      onClick={() => {
        askForAReason(
          props.preparedConfig,
          props.is_brico,
          props.MultihelpersJobCode
        );
      }}
    >
      Rechazar el servicio
    </button>
  );
}
