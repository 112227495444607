import Swal from "sweetalert2";

import './popup-message.styles.scss';

const GenericPopupWithSelectBox = (
    icon,
    title,
    confirmButtonText,
    cancelButtonText,
    inputOptions,
    inputPlaceholder,
    customClass,
    funct
) => {
  Swal.fire({
    icon: icon,
    title: title,
    input: 'select',
    inputOptions: inputOptions,
    inputPlaceholder: inputPlaceholder,
    allowOutsideClick: false,
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    inputValidator: function (value) {
      return new Promise(function (resolve, reject) {
        if (value !== '') {
          resolve();
        } else {
          resolve('Tiene que elegir una opción de la lista');
        }
      });
    },
    customClass:customClass
  }).then(function(result){
    funct(result);
  });
};

export default GenericPopupWithSelectBox;
