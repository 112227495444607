import axios from "axios";
import ENDPOINTS from "../utils/endpoints";

export default class NextActionList {
  #nextActions = [];
  #preparedConfig = null;
  #incidenceNextAction = null;

  constructor(preparedConfig) {
    this.#preparedConfig = preparedConfig;
  }

  async initNextActions() {
    const response = await axios.get(
      ENDPOINTS.nextActionCached,
      this.#preparedConfig
    );

    if (response.status == 200) {
      this.#nextActions = response.data;
    }

    for (let nextAction of this.#nextActions) {
      if (nextAction.NextActionKey == "RESOLVER_INCIDENCIA") {
        this.#incidenceNextAction = nextAction;
      }
    }
  }

  getNextActionColor(taskObject) {
    let date = null;
    let action = null;
    if (taskObject.IncidenceNextActionDate) {
      date = taskObject.IncidenceNextActionDate;
      action = this.#incidenceNextAction;
    } else {
      if (
        taskObject.NextActionUid &&
        taskObject.MultihelpersJobContactedInternalDate
      ) {
        date = taskObject.MultihelpersJobContactedInternalDate;
        action = this.#nextActions.find(
          (action) => action.NextActionUid == taskObject.NextActionUid
        );
      }
    }

    if (action) {
      date = new Date(date);
      const diff = (date.valueOf() - new Date().valueOf()) / 1000 / 60 / 60;

      if (
        action.NextActionChangeColorToRedInHours &&
        diff <= action.NextActionChangeColorToRedInHours
      ) {
        return "red";
      }

      if (
        action.NextActionChangeColorToOrangeInHours &&
        diff <= action.NextActionChangeColorToOrangeInHours
      ) {
        return "orange";
      }

      if (
        action.NextActionChangeColorToYellowInHours &&
        diff <= action.NextActionChangeColorToYellowInHours
      ) {
        return "yellow";
      }
    }

    return "";
  }

  getNextActionDate(taskObject) {
    let date = null;

    if (taskObject.IncidenceNextActionDate) {
      date = taskObject.IncidenceNextActionDate;
    }

    if (
      taskObject.NextActionUid &&
      taskObject.MultihelpersJobContactedInternalDate
    ) {
      date = taskObject.MultihelpersJobContactedInternalDate;
    }

    if (date) {
      date = new Date(date);
      return date.toLocaleDateString();
    }

    return "-";
  }
}
