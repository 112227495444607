import Swal from "sweetalert2";

import './popup-message.styles.scss';

const GenericPopupWithTextarea = (
    icon,
    title,
    confirmButtonText,
    cancelButtonText,
    customClass,
    funct
) => {
  Swal.fire({
    icon: icon,
    title: title,
    input: 'textarea',
    allowOutsideClick: false,
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    customClass:customClass
  }).then(function(result){
    funct(result);
  });
};

export default GenericPopupWithTextarea;
