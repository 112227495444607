import Swal from "sweetalert2";

import './popup-message.styles.scss';

const GenericAsyncPopupComponent = async (icon, title, showConfirmButton, showCancelButton, showCloseButton, confirmButtonText, cancelButtonText, customClass, funct) => {
  await Swal.fire({
    icon: icon,
    title: title,
    allowOutsideClick: false,
    showConfirmButton: showConfirmButton,
    showCancelButton: showCancelButton,
    showCloseButton: showCloseButton,
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    customClass: customClass,
  }).then((result) => {
    funct(result);
  });
};

export default GenericAsyncPopupComponent;
