export const logUserAccessTypes = Object.freeze({
  Public: 0,
  Helper: 1,
  Internal: 2,
  Private: 3,
});

export const logUserTypes = Object.freeze({
  0: "Equipo MH",
  1: "Helper",
  2: "Cliente",
  3: "Auto",
});
