import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";

export const SidebarData = [
  {
    title: "Servicios asignados",
    path: "/servicios_asignados",
    cName: "nav-text",
  },
  {
    title: "Servicios en Curso",
    path: "/servicios",
    cName: "nav-text",
  },
  {
    title: "Servicios finalizados",
    path: "/servicios_finalizados",
    cName: "nav-text",
  },
  {
    title: "Calendario",
    path: "/calendario",
    cName: "nav-text",
  },
  {
    title: "Configuración",
    path: "/config",
    cName: "nav-text",
  },
  {
    title: "Facturas",
    path: "/invoices",
    cName: "nav-text",
  },
];
