import Swal from "sweetalert2";

import './popup-message.styles.scss';

const PopupWithSelectBox = (icon, title, taskId, route,confirmButtonText,cancelButtonText,inputOptions,inputPlaceholder,ConfirmFunction) => {
  Swal.fire({
    icon: icon,
    title: title,
    input: 'select',
    inputOptions: inputOptions,
    inputPlaceholder: inputPlaceholder,
    allowOutsideClick: false,
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    inputValidator: function (value) {
      return new Promise(function (resolve, reject) {
        if (value !== '') {
          resolve();
        } else {
          resolve('Tiene que elegir una opción de la lista');
        }
      });
    },
    customClass: 'mh-modal'
  }).then(function(result){
    if(result.isConfirmed) {
      ConfirmFunction(result.value);
    }else{
      window.location = '/' + taskId;
    }
  });
};

export default PopupWithSelectBox;
