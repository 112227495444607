import React from "react";

import "./task-log.styles.scss";

const TaskLog = ({ logList }) => {
  if (logList === undefined) {
    return <div>Solicitando registros 🐒</div>;
  }

  if (logList.length === 0) {
    return <div>No hay registros 🙈</div>;
  }

  return (
    <table className="task-log">
      <thead>
        <tr>
          <th>Fecha</th>
          <th>Tipo</th>
          <th>Descripción</th>
        </tr>
      </thead>
      <tbody>
        {logList.map((log, index) => (
          <tr key={index}>
            <td>{log.UpdatedAt}</td>
            <td>{log.UserType}</td>
            <td>{log.Action}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TaskLog;
