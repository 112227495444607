import React from 'react';
import Header from '../../../components/header/header.component';
import Footer from '../../../components/footer/footer.component';
import InvoiceListComponent from '../../../components/invoice/list/invoice-list.component';


const InvoiceList = (props) => {
  return (
    <div className='parent'>
      <Header /> 
      <div className='task-list'>
        <div className='task-list-title'>
          <h3>Mis facturas</h3>
        </div>

        <InvoiceListComponent {...props} />

      </div>

      <Footer />
    </div>
  )
};

export default InvoiceList;
