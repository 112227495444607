import React from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const TaskLogCreateModal = ({ isOpen, handleBack, handleSubmit }) => {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      contentLabel="Modal Create Log"
      className="modal-init"
    >
      <span>
        <FontAwesomeIcon
          className="icon-back"
          icon={faArrowLeft}
          onClick={handleBack}
        />
      </span>

      <div>
        <span className="modal-log-title bold">
          Enviar mensaje a Multihelpers
        </span>
      </div>
      <form onSubmit={handleSubmit} className="modal-log-body">
        <div className="modal-log-body-item">
          <label className="modal-log-body-item-title">
            Comentario
            <textarea
              name="log-text-action"
              className="modal-log-body-item-textarea"
            />
          </label>
        </div>
        <div>
          <button
            className="button-documentos custom-button ficha-button-documentos"
            type="submit"
          >
            Crear registro
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default TaskLogCreateModal;
