import React from 'react';

import Header from '../../components/header/header.component';
import AcceptChangeDateCalendar from '../../components/accept-change-date-calendar/accept-change-date-calendar.component';
import Footer from '../../components/footer/footer.component';

const ChangeDateTaskCalendar = (props) => (
  <div className='parent'>
    <Header />

    <AcceptChangeDateCalendar {...props} />

    <Footer />
  </div>
);

export default ChangeDateTaskCalendar;
