import React from 'react';

import Header from '../../components/header/header.component';
import Task from '../../components/task-acept-reject/task.component';
import Footer from '../../components/footer/footer.component';

const TaskAceptReject = (props) => (
  <div className='parent'>
    <Header />

    <Task {...props} />

    <Footer />
  </div>
);

export default TaskAceptReject;
