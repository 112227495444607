import React from "react";
import axios from "axios";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import LoadingSkeleton from "../loading-skeleton/loading-skeleton.component";
import PopupYesNoWithSpecificRedirect from "../popup-message/popup-yes-no-with-specific-redirect.component";
import PopupConfirmWithoutIconAndCancel from "../popup-message/popup-confirm-without-icon-and-cancel.component";

import { Redirect } from "react-router-dom";
import { GoCalendar, GoFile } from "react-icons/go";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import "./task.styles.scss";
import ButtonDeliverDiagnostic from "./ButtonDeliverDiagnostic.component";
import ButtonBricoSendBudget from "./ButtonBricoSendBudget.component";
import ButtonSendGeneralBudget from "./ButtonSendGeneralBudget.component";
import ButtonRejectService from "./ButtonRejectService.component";

import TaskLog from "../task-log/task-log.component";
import TaskLogCreateModal from "../task-log/task-log-create-modal.component";

// Constants
import { logUserTypes } from "../../utils/constants";
import ENDPOINTS from "../../utils/endpoints";

class task extends React.Component {
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);

    this.state = {
      serviceData: [],
      hasImages: false,
      thistaskId: this.props.match.params.taskId,
      thisTaskcp: "",
      userData: this.props.location.search
        ? this.props.location.search
        : JSON.parse(localStorage.getItem("task")).userData,
      redirect: false,
      showFilters: false,
      filtersTitle: "Ver Filtros",
      idInputValue: "",
      thisClient: [],
      filteredArray: [],
      selectedStageOption: "",
      stageOptions: [],
      selectedDateOption: "",
      dateOptions: [],
      currentPage: 1,
      uploadToken: "",
      invoice: "loading",
      token: false,
      helperId: false,
      taskBudgetUrl: "",
      budget: "",
      preparedConfig: {},
      back_to: "/servicios",
      showModalCreateLog: false,
    };

    localStorage.setItem("task", JSON.stringify(this.state));

    if (localStorage.getItem("back_to")) {
      this.state.back_to = localStorage.getItem("back_to");
      localStorage.removeItem("back_to");
    }
  }

  controller = new AbortController();

  handlePageChange = (page, e) => {
    this.setState({
      currentPage: page,
    });
  };

  decryptUserData() {
    const decryptedStr = this.handleHash(this.state.userData);
    console.log(decryptedStr);
    const passwordArr = decryptedStr.split("password=");
    const pass = passwordArr[1];
    const usernameArr = passwordArr[0].split("username=");
    const user = usernameArr[1].slice(0, -1);

    return [user, pass];
  }

  handleHash(hash) {
    try {
      return atob(hash.substr(1));
    } catch (e) {
      this.setState({ redirect: true });
    }
  }

  loginCheckAPI(urlLoginCheckAPI, userObject, secureArray) {
    axios.post(urlLoginCheckAPI, userObject).then(
      (result) => {
        this.setState({ token: result.data.token });

        const preparedConfig = {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
            accept: "application/json",
          },
        };

        this.setState({ preparedConfig: preparedConfig });

        const urlGetHelperId = ENDPOINTS.userMe;
        this.getHelperId(urlGetHelperId, preparedConfig, secureArray);
      },
      (error) => {
        window.location.href = process.env.REACT_APP_WEB_LOGIN_URL;
      }
    );
  }

  getHelperId(urlGetHelperId, preparedConfig, secureArray) {
    axios.get(urlGetHelperId, preparedConfig).then(
      (result) => {
        this.setState({
          helperId: result.data.UsersCode,
        });

        if (!result.data.helper_active) {
          this.setState({ redirect: true });
        }

        this.setState({
          userBasicToken: result.data.auth_content,
        });

        localStorage.setItem("taskList", JSON.stringify(this.state));

        const urlGetHelperTasks =
          ENDPOINTS.services.serviceData + this.state.thistaskId;

        this.getHelperTasks(urlGetHelperTasks, preparedConfig);
      },
      (error) => {
        console.log(error);
        this.setState({ redirect: true });
      }
    );
  }

  componentDidMount() {
    let secureArray = this.decryptUserData();

    const userObject = {
      username: secureArray[0],
      password: secureArray[1],
    };
    const urlLoginCheckAPI = ENDPOINTS.urlLoginCheckAPI;

    this.loginCheckAPI(urlLoginCheckAPI, userObject, secureArray);
  }

  componentWillUnmount() {
    this.signal.cancel("API is being canceled");
    this.controller.abort();
  }

  getHelperTasks(urlGetHelperTasks, preparedConfig) {
    axios.get(urlGetHelperTasks, preparedConfig).then(
      (result) => {
        this.setState({
          serviceData: result.data,
          hasImages: result.data.images,
        });

        const urlGetTaskPostalCode =
          ENDPOINTS.general.cp + this.state.serviceData.postalcode;
        const urlGetClient = ENDPOINTS.general.ExtraUserData;
        const urlGetbudget = ENDPOINTS.general.budget;
        const urlGetLogTask = `${ENDPOINTS.taskLogHelper.list}/${this.state.serviceData.MultihelpersJobUid}`;

        this.getCp(urlGetTaskPostalCode, preparedConfig);
        this.getClient(urlGetClient, preparedConfig);
        this.getTaskBudget(urlGetbudget, preparedConfig);
        this.getLog(urlGetLogTask, preparedConfig);
      },
      (error) => {
        this.setState({ redirect: true });
      }
    );
  }

  getTaskBudget(urlGetbudget, preparedConfig) {
    axios
      .get(urlGetbudget, {
        params: {
          MultihelpersJobUid: this.state.serviceData.MultihelpersJobUid,
          BudgetIsFavorite: "1",
        },
        ...preparedConfig,
      })
      .then((result) => {
        if (result.data.length > 0 && result.data[0].Binary.length > 0) {
          this.setState({
            taskBudgetUrl: result.data[0].Binary[0].BinaryUrl,
          });
        } else {
          this.setState({
            taskBudgetUrl: "SIN_PRESUPUESTO",
          });
        }

        if (this.state.serviceData.helperInvoiceUrl) {
          this.getTaksDocumentsAutenticated(
            this.state.serviceData.helperInvoiceUrl
          );
        } else {
          this.setState({
            invoice: "",
          });
        }
      });
  }

  getTaksDocumentsAutenticated(url) {
    const preparedConfig = {
      headers: { Authorization: `Basic ${this.state.userBasicToken}` },
      responseType: "arraybuffer",
    };
    axios.get(url, preparedConfig).then((result) => {
      let base64data = Buffer.from(result.data, "binary").toString("base64");
      let mimeType = result.headers["content-type"];
      this.setState({ invoice: `data:${mimeType};base64,${base64data}` });
    });
  }

  getCp(urlGetHelperTasks, preparedConfig) {
    axios.get(urlGetHelperTasks, preparedConfig).then(
      (result) => {
        this.setState({
          thisTaskcp: result.data[0].PostalCodeZip,
        });
      },
      (error) => {
        this.setState({ redirect: true });
      }
    );
  }

  getClient(urlGetHelperTasks, preparedConfig) {
    axios
      .get(urlGetHelperTasks, {
        params: { UsersUid: this.state.serviceData.fosUser },
        ...preparedConfig,
      })
      .then(
        (result) => {
          this.setState({
            thisClient: result.data[0],
          });
        },
        (error) => {
          this.setState({ redirect: true });
        }
      );
  }

  getLog(urlGetLogTaks, preparedConfig) {
    axios.get(urlGetLogTaks, preparedConfig).then(
      (result) => {
        const results = result.data
          .filter((log) => log.AccessType === "0" || log.AccessType === "1")
          .map((log) => {
            const { updated_at, UserType, ...rest } = log;
            const UserTypeCasted = logUserTypes[log.UserType];

            return {
              ...rest,
              UpdatedAt: updated_at,
              UserType: UserTypeCasted,
            };
          });

        this.setState({
          serviceData: {
            ...this.state.serviceData,
            logList: results,
          },
        });
      },
      (error) => {
        this.setState({ redirect: true });
      }
    );
  }

  handleCreateLog = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());

    const urlCreateLog = `${ENDPOINTS.taskLogHelper.create}`;

    const preparedConfig = {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
        accept: "application/json",
      },
    };

    axios
      .post(
        urlCreateLog,
        {
          MultihelpersJobUid: this.state.serviceData.MultihelpersJobUid,
          Action: data["log-text-action"],
          MultihelpersJobLogUid: uuidv4(),
        },
        preparedConfig
      )
      .then(
        (result) => {
          this.setState({
            showModalCreateLog: false,
          });

          const urlGetLogTask = `${ENDPOINTS.taskLogHelper.list}/${this.state.serviceData.MultihelpersJobUid}`;
          this.getLog(urlGetLogTask, preparedConfig);
        },
        (error) => {
          console.log(error);
        }
      );
  };
  goToimages(taskId, MultihelpersJobUid) {
    window.location = "/" + taskId + "/" + MultihelpersJobUid + "/ver-fotos";
  }

  goToCalendar(taskId) {
    window.location = "/calendario";
  }

  goToDocumentos(taskId) {
    window.location = "/" + taskId + "/documentos";
  }

  removeArrDuplicates = (originalArray, prop) => {
    let newArray = [];
    let lookupObject = {};

    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }

    return newArray;
  };

  forceDownload(url, fileName) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.setRequestHeader("Authorization", "Basic " + this.state.userBasicToken);
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement("a");
      tag.href = imageUrl;
      tag.download = fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  }

  downloadFileOverridingFilename(url, filename) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var blob = this.response;
      var a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = filename;
      a.dispatchEvent(new MouseEvent("click"));
    };
    xhr.send(null);
  }

  formatPhone(phone) {
    if (phone) {
      if (phone.substr(0, 2) == "34") return "+" + phone;
      else if (phone.substr(0, 3) == "+34") return phone;
      else return phone;
    }
  }

  goToCreateBudgetVisitState(taskId) {
    PopupYesNoWithSpecificRedirect(
      "",
      "¿Has llamado al cliente?",
      "Si",
      "No",
      "mh-modal3",
      (result) => {
        if (result.value) {
          window.location = "/" + taskId + "/presupuestar/0";
        } else {
          window.location = "/" + taskId + "/presupuestar/1";
        }
      }
    );
  }

  render() {
    let diagnostico = false;
    if (this.state.serviceData.shortDescriptionName !== undefined) {
      if (
        this.state.serviceData.shortDescriptionName
          .toUpperCase()
          .includes("DIAGNÓSTICO")
      ) {
        diagnostico = true;
      }
    }

    if (this.state.redirect) {
      return <Redirect to={{ pathname: "/" }} />;
    }

    if (
      typeof this.state.serviceData === "undefined" ||
      this.state.serviceData.length === 0
    ) {
      return <LoadingSkeleton />;
    }
    let uploadToken = this.state.uploadToken;
    let token = this.state.token;

    const task = this.state.serviceData;
    const client = { ...this.state.thisClient };

    const myEvent = {
      id: this.state.thistaskId || "-",
      stage: task.taskStage || "-",
      summary: task.summary || task.descripcion,
      cp: this.state.thisTaskcp || "-",
      fechaEjecucion: task.executionDate,
    };

    myEvent.addressStreet =
      task.addressStreet || (task.addressStreet == 0 ? 0 : "");
    myEvent.addressNumber =
      task.addressNumber || (task.addressNumber == 0 ? 0 : "");
    myEvent.addressObservations =
      task.addressObservations || (task.addressObservations == 0 ? 0 : "");
    myEvent.thisTaskcp = this.state.thisTaskcp || "-";
    myEvent.preLocation =
      !myEvent.addressStreet &&
      !myEvent.addressNumber &&
      !myEvent.addressObservations
        ? "-"
        : myEvent.addressStreet +
          " " +
          myEvent.addressNumber +
          " " +
          myEvent.addressObservations;

    myEvent.location = myEvent.preLocation + " (" + myEvent.thisTaskcp + ")";

    myEvent.priceFinal = task.priceFinal || (task.priceFinal === 0 ? 0 : "-");
    myEvent.priceHelper =
      task.priceHelper || (task.priceHelper === 0 ? 0 : "-");
    myEvent.priceHelperWithoutIVA =
      Math.round((myEvent.priceHelper / 1.21 + Number.EPSILON) * 100) / 100;
    if (myEvent.priceFinal == "-" || myEvent.priceHelper == "-") {
      myEvent.pricecomision = "-";
    } else {
      myEvent.pricecomision = task.priceFinal - task.priceHelper;
    }

    if (!isNaN(parseFloat(task.priceFinal)))
      myEvent.priceFinal = parseFloat(task.priceFinal).toFixed(2);
    if (!isNaN(parseFloat(task.priceHelper)))
      myEvent.priceHelper = parseFloat(task.priceHelper).toFixed(2);
    if (!isNaN(parseFloat(myEvent.pricecomision)))
      myEvent.pricecomision = parseFloat(myEvent.pricecomision).toFixed(2);

    myEvent.executionDate =
      task.executionDate &&
      moment(task.executionDate, "YYYY-MM-DDTHH:mm:ss").isValid()
        ? moment(task.executionDate, "YYYY-MM-DDTHH:mm:ss").format(
            "DD/MM/YYYY HH:mm"
          )
        : "-";
    myEvent.finishedAt =
      task.finishedAt &&
      moment(task.finishedAt, "YYYY-MM-DDTHH:mm:ss").isValid()
        ? moment(task.finishedAt, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY")
        : "-";
    myEvent.helperPaidAt =
      task.helperPaidAt &&
      moment(task.helperPaidAt, "YYYY-MM-DDTHH:mm:ss").isValid()
        ? moment(task.helperPaidAt, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY")
        : "-";

    // client.billingFirstName = client.billingFirstName || "-";
    // client.billingLastName = client.billingLastName || "-";
    // client.uin = client.uin || "-";
    // client.billingAddress = client.billingAddress || "-";

    myEvent.showEditDate = true;
    myEvent.askReason = true;
    myEvent.isExecutionDateNULL = true;

    // Comprobamos que podemos mostrar el botón de modificar fecha
    if (moment(task.executionDate, "YYYY-MM-DDTHH:mm:ss").isValid()) {
      myEvent.isExecutionDateNULL = false;
      if (
        moment(task.executionDate, "YYYY-MM-DDTHH:mm:ss").diff(new Date()) > 0
      ) {
        myEvent.showEditDate = true;
      }
    } else if (task.executionDate == null || task.executionDate == "") {
      myEvent.showEditDate = true;
      myEvent.askReason = false;
    }

    myEvent.phone_number = "No definido";

    // Comprobamos si el teléfono esta definido
    if (
      this.state.thisClient.ExtraUserDataPhone != null ||
      this.state.thisClient.ExtraUserDataPhone != ""
    ) {
      myEvent.phone_number = this.state.thisClient.ExtraUserDataPhone;
    }

    //console.log(this.state.thisClient)
    myEvent.client_name = "";

    if (
      this.state.thisClient.ExtraUserDataFirstName != null &&
      this.state.thisClient.ExtraUserDataFirstName != ""
    ) {
      myEvent.client_name += this.state.thisClient.ExtraUserDataFirstName;
    }

    if (
      this.state.thisClient.ExtraUserDataLastName != null &&
      this.state.thisClient.ExtraUserDataLastName != ""
    ) {
      myEvent.client_name += " " + this.state.thisClient.ExtraUserDataLastName;
    }

    return (
      <div className="main-container">
        <Link
          className="goBack"
          to={{
            pathname: this.state.back_to,
            token: this.state.token,
            helperId: this.state.helperId,
          }}
        >
          <span>
            <FontAwesomeIcon icon={faArrowLeft} />
          </span>
        </Link>

        <div className="ficha-container">
          <div className="ficha-task">
            <div className="ficha-header-title">ID {myEvent.id}</div>
          </div>
          <div className="ficha-servicio">
            <div className="ficha-title">Descripción del servicio</div>
            <div style={{ whiteSpace: 'pre-line' }}>
              {myEvent.summary}
            </div>
            <div className="ficha-servicio-footer">
              <div className="ficha-bold">Código postal {myEvent.cp}</div>
              {this.state.hasImages ? (
                <a
                  onClick={() => {
                    this.goToimages(
                      this.state.thistaskId,
                      this.state.serviceData.MultihelpersJobUid
                    );
                  }}
                  href="#"
                >
                  Ver fotografías
                </a>
              ) : (
                <div className="link-noimages">No hay fotografías</div>
              )}

            </div>
          </div>
          <div>
            <div className="ficha-realizacion">
              <div className="ficha-title">Realización del servicio</div>
              <div>Fecha de ejecución: {myEvent.executionDate}</div>
              <div>
                <p>Dirección: {myEvent.location}</p>
                <p>Cliente: {myEvent.client_name}</p>
                <p>Número de contacto: {myEvent.phone_number}</p>
              </div>
              <div className="ficha-realizacion-calendario">
                <GoCalendar className="documenticon-si">
                  <a
                    onClick={() => {
                      this.goToCalendar(this.state.thistaskId);
                    }}
                    href="#"
                  ></a>
                </GoCalendar>
                <a
                  onClick={() => {
                    this.goToCalendar(this.state.thistaskId);
                  }}
                  href="#"
                >
                  Calendario
                </a>
              </div>

              {myEvent.showEditDate && (
                <button
                  className="button-documentos custom-button ficha-button-documentos"
                  onClick={() => {
                    PopupConfirmWithoutIconAndCancel(
                      "Recuerde que previamente tiene que haber concretado la fecha con el cliente. \n\n ¿Ha concretado la fecha?",
                      this.state.thistaskId +
                        "/cambiar_fecha/" +
                        (myEvent.askReason | 0) +
                        "/0",
                      "Si",
                      "No"
                    );
                  }}
                >
                  {myEvent.isExecutionDateNULL ? "Establecer" : "Modificar"}{" "}
                  fecha de ejecución
                </button>
              )}

              {myEvent.stage == "VISITA" ||
              myEvent.stage == "TAREA EN PROCESO" ? (
                <div
                  className="custom-button call-client-button-container"
                  style={{
                    paddingBottom: "0.7rem !important",
                    paddingTop: "0.7rem !important",
                  }}
                >
                  <a
                    href={"tel:" + this.formatPhone(client.ExtraUserDataPhone)}
                    className="call-client-button"
                  >
                    Llamar a cliente
                  </a>
                </div>
              ) : (
                <div></div>
              )}
              <button
                className="button-documentos custom-button ficha-button-documentos"
                onClick={() => {
                  this.goToDocumentos(this.state.thistaskId);
                }}
                style={{ marginTop: "0px !important" }}
              >
                Subir documentos del servicio
              </button>

              {myEvent.stage == "VISITA" &&
                !diagnostico &&
                !this.state.serviceData.is_brico && (
                  <button
                    className="button-documentos custom-button ficha-button-documentos"
                    onClick={() => {
                      this.goToCreateBudgetVisitState(this.state.thistaskId);
                    }}
                    style={{
                      marginTop: "0px !important",
                    }}
                  >
                    Mandar presupuesto de visita
                  </button>
                )}

              {!this.state.serviceData.is_brico &&
                !diagnostico &&
                myEvent.stage != "VISITA" && (
                  <ButtonSendGeneralBudget taskId={this.state.thistaskId} />
                )}

              {diagnostico && !this.state.serviceData.is_brico && (
                <ButtonDeliverDiagnostic
                  taskId={this.state.thistaskId}
                  preparedConfig={this.state.preparedConfig}
                />
              )}

              {this.state.serviceData.is_brico && (
                <ButtonBricoSendBudget
                  taskId={this.state.thistaskId}
                  className="button-documentos custom-button ficha-button-documentos"
                />
              )}

              {!this.state.serviceData.finishedAt && (
                <ButtonRejectService
                  className="custom-button custom-button3 button-documentos ficha-button-documentos"
                  preparedConfig={this.state.preparedConfig}
                  is_brico={this.state.serviceData.is_brico == "1"}
                  MultihelpersJobCode={this.state.thistaskId}
                />
              )}
            </div>
          </div>
          <div className="ficha-facturacion-contenedor">
            <div className="ficha-title">Facturación</div>
            {myEvent.isConfirmityDocReceived && (
              <div className="ficha-facturacion-contenedor-bloque">
                <div>Nombre Cliente: Tremad Sharing Sl.</div>
                <div>CIF/NIF: B71240485</div>
                <div>
                  Dirección Facturación: Parque Comercial Galaria (cl u), 3 -
                  OFICINA 1.6, Galar, 31191, Navarra{" "}
                </div>
              </div>
            )}
            <div className="ficha-facturacion-contenedor-bloque">
              <div>
                Importe total Factura: {myEvent.priceHelperWithoutIVA}€ + IVA
              </div>
            </div>
            <div className="ficha-facturacion-contenedor-bloque">
              <div>Fecha de finalización de servicio: {myEvent.finishedAt}</div>
              <div>Fecha de pago de servicio: {myEvent.helperPaidAt}</div>
            </div>
            {this.state.invoice == "loading" ? (
              <div className="ficha-factura-button">
                <GoFile className="documenticon-no " />
                Localizando factura del servicio...
              </div>
            ) : this.state.invoice ? (
              <a
                className="ficha-factura-button"
                download={`${this.state.thistaskId}_${moment().format(
                  "DD-MM-YYYY"
                )}_Factura`}
                href={this.state.invoice}
              >
                <GoFile className="documenticon-si" />
                Factura del servicio
              </a>
            ) : (
              <div className="ficha-factura-button">
                <GoFile className="documenticon-no " />
                Sin factura del servicio
              </div>
            )}
          </div>
          <div className="ficha-log-contenedor">
            <TaskLogCreateModal
              isOpen={this.state.showModalCreateLog}
              handleBack={() => this.setState({ showModalCreateLog: false })}
              handleSubmit={this.handleCreateLog}
            />

            <div className="ficha-title">Registro de Eventos</div>
            <div className="ficha-log-contenedor-bloque">
              <TaskLog logList={this.state.serviceData.logList} />
            </div>
            <div className="ficha-log-contenedor-buttons">
              <button
                className="button-documentos custom-button ficha-button-documentos"
                onClick={() => {
                  this.setState({ showModalCreateLog: true });
                }}
              >
                Enviar mensaje a Multihelpers
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default task;
