import React from "react";
import {Col, Row} from "react-bootstrap";
import './create-budget.styles.scss';

class RowComponent extends React.Component {

    conceptChangeHandler = event => {
        if(event.target.value != ''){
            document.getElementById('price-'+this.props.element_number).readOnly = false;
        }else{
            document.getElementById('price-'+this.props.element_number).readOnly = true;
            document.getElementById('price-'+this.props.element_number).value="";
        }
        this.props.calculateTotal();
    };

    priceChangeHandler = event => {
        const re = /[0-9]|['.']/;

        let all_string = document.getElementById('price-'+this.props.element_number).value + event.key;

        if(!re.test(event.key)){
            event.preventDefault()
        }

        if(event.key == '.' && document.getElementById('price-'+this.props.element_number).value.includes('.')){
            event.preventDefault()
        }

        if(all_string.includes('.')){
            if(all_string.split('.')[1].length > 2){
                event.preventDefault()
            }
        }
    }

    render() {

        let concept_placeholder = "Concepto " + this.props.element_number
        let price_placeholder = '€'
        let id_concept = "concept-"+this.props.element_number
        let id_number = 'price-'+this.props.element_number

        return(
            <Row className='mb-3' key={"row-budget-"+this.props.element_number}>
                <Col xs={9} key={"col-1-"+this.props.element_number}>
                    <input type="text" onChange={this.conceptChangeHandler} key={id_concept} id={id_concept} className='concept-input-text-row' name="name" style={{width:'100%'}} placeholder={concept_placeholder} />
                </Col>
                <Col xs={3} key={"col-2-"+this.props.element_number}>
                    <input
                        type="number"
                        step="0.01"
                        onKeyPress={this.priceChangeHandler}
                        onChange={() => {this.props.calculateTotal()}}
                        key={id_number}
                        id={id_number}
                        className='input-number-row'
                        name="name"
                        placeholder={price_placeholder}
                        style={{width:'100%'}}
                        readOnly
                    />
                </Col>
            </Row>
        )
    }
}

export default RowComponent;