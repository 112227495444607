import Swal from "sweetalert2";
import './popup-message.styles.scss';

const PopupMessageWithoutRedirect = (icon, title) => {
  Swal.fire({
    icon: icon,
    title: title,
    allowOutsideClick: false,
    showConfirmButton: false,
    showCloseButton: true,
    customClass: 'mh-modal'
  });
};

export default PopupMessageWithoutRedirect;
