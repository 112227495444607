import React from "react";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";

import TaskTitle from "../../components/task-title/task-title.component";
import UploadPhotosButton from "../../components/action-buttons/upload-photos-button.component";
import ConfirmityDocButton from "../../components/action-buttons/confirmity-doc-button.component";
import LoadingSkeleton from "../../components/loading-skeleton/loading-skeleton.component";
import PopupMessage from "../../components/popup-message/popup-message.component";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./profile.styles.scss";

// Constants
import ENDPOINTS from "../../utils/endpoints";

class Profile extends React.Component {
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);

    this.state = {
      taskId: this.props.match.params.taskId,
      userData: this.props.location.search
        ? this.props.location.search
        : JSON.parse(localStorage.getItem("task")).userData,
      isPhotosBefore: null,
      isPhotosAfter: null,
      isDocumentConfirm: null,
      isInvoice: null,
      token: null,
      helperId: null,
      uploadToken: null,
      redirect: false,
    };

    localStorage.setItem("task", JSON.stringify(this.state));
  }

  controller = new AbortController();

  stopPreviousEvents(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  handleHash(hash) {
    try {
      return atob(hash.substr(1));
    } catch (e) {
      this.setState({ redirect: true });
    }
  }

  handleOnDocumentConfirmClick(e) {
    if (this.state.isDocumentConfirm) {
      this.stopPreviousEvents(e);
      PopupMessage(
        "success",
        "Tu documento de conformidad ya se firmó correctamente",
        "none"
      );
    } else if (!this.state.isPhotosAfter) {
      this.stopPreviousEvents(e);
      PopupMessage(
        "warning",
        "Antes debes subir las fotos del servicio",
        "none"
      );
    }
  }

  handleOnPhotosAfterClick(e) {
    if (!this.state.isPhotosBefore) {
      this.stopPreviousEvents(e);
      PopupMessage(
        "warning",
        "Antes debes subir las fotos de antes del servicio",
        "none"
      );
    }
  }

  handleOnInvoiceClick(e) {
    //   if(!this.state.isDocumentConfirm) {
    //       this.stopPreviousEvents(e);
    //       PopupMessage('warning', 'Antes debes subir el documento de coformidad', 'none');
    //   }
  }

  decryptUserData() {
    const decryptedStr = this.handleHash(this.state.userData);
    const passwordArr = decryptedStr.split("password=");
    const pass = passwordArr[1];
    const usernameArr = passwordArr[0].split("username=");
    const user = usernameArr[1].slice(0, -1);

    return [user, pass];
  }

  getPendingDocs(urlPendingDocs, preparedConfig) {
    axios.get(urlPendingDocs, preparedConfig).then(
      (result) => {
        this.setState({
          isPhotosBefore: result.data.areAnyImageFromBefore,
          isPhotosAfter: result.data.areAnyImageFromAfter,
          isDocumentConfirm: result.data.isConformityDocumentSent,
          isInvoice: result.data.isHelperInvoiceSent,
        });
        localStorage.setItem("task", JSON.stringify(this.state));
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getHelperId(urlGetHelperId, preparedConfig, secureArray) {
    axios.get(urlGetHelperId, preparedConfig).then(
      (result) => {
        this.setState({
          helperId: result.data.UsersCode,
        });

        if (!result.data.helper_active) {
          this.setState({ redirect: true });
        }

        this.setState({
          uploadToken: result.data.auth_content,
        });

        localStorage.setItem("task", JSON.stringify(this.state));
      },
      (error) => {
        console.log(error);
      }
    );
  }

  loginCheckAPI(urlLoginCheckAPI, userObject, secureArray) {
    axios
      .post(urlLoginCheckAPI, userObject, { cancelToken: this.signal.token })
      .then(
        (result) => {
          this.setState({ token: result.data.token });
          localStorage.setItem("task", JSON.stringify(this.state));

          const urlPendingDocs =
            ENDPOINTS.services.pendingDocumentation + this.state.taskId;

          const preparedConfig = {
            headers: {
              Authorization: `Bearer ${this.state.token}`,
              accept: "application/json",
            },
            cancelToken: this.signal.token,
          };

          this.getPendingDocs(urlPendingDocs, preparedConfig);

          const urlGetHelperId = ENDPOINTS.userMe;
          this.getHelperId(urlGetHelperId, preparedConfig, secureArray);
        },
        (error) => {
          window.location.href = process.env.REACT_APP_WEB_LOGIN_URL;
        }
      );
  }

  componentDidMount() {
    let secureArray = this.decryptUserData();
    const userObject = {
      username: secureArray[0],
      password: secureArray[1],
    };
    const urlLoginCheckAPI = ENDPOINTS.urlLoginCheckAPI;

    this.loginCheckAPI(urlLoginCheckAPI, userObject, secureArray);
  }

  componentWillUnmount() {
    this.signal.cancel("API is being canceled");
    this.controller.abort();
  }

  render() {
    let taskId = this.state.taskId;

    if (this.state.redirect) {
      return <Redirect to={{ pathname: "/" }} />;
    }

    if (this.state.isInvoice === null || this.state.uploadToken === null)
      return <LoadingSkeleton />;

    let userData = this.state.userData;
    let uploadToken = this.state.uploadToken;
    let token = this.state.token;
    let urlConfirmity =
      process.env.REACT_APP_URL_REDIRECT_TO_CONFIRMITY_DOC + taskId;
    let urlConfirmity2 =
      "/" + this.state.taskId + "/subir-documento-conformidad";

    return (
      <div className="profile">
        <Link
          className="back-to-services"
          style={{
            position: "absolute",
            marginTop: "2rem",
            left: "10rem",
          }}
          to={{
            pathname: "/" + this.state.taskId,
            token: this.state.token,
            helperId: this.state.helperId,
          }}
        >
          <span>
            <FontAwesomeIcon icon={faArrowLeft} />
          </span>
        </Link>

        <TaskTitle taskId={taskId} />

        <UploadPhotosButton
          taskId={taskId}
          userData={userData}
          token={token}
          uploadToken={uploadToken}
          uploadPath={"/subir-fotos-antes"}
          buttonText={"Fotos antes"}
          isPhotos={this.state.isPhotosBefore}
        />

        <UploadPhotosButton
          taskId={taskId}
          userData={userData}
          token={token}
          uploadToken={uploadToken}
          uploadPath={"/subir-fotos-despues"}
          buttonText={"Fotos después"}
          isPhotos={this.state.isPhotosAfter}
          onClick={(e) => this.handleOnPhotosAfterClick(e)}
        />

        <ConfirmityDocButton
          href={urlConfirmity}
          photosAfter={this.state.isPhotosAfter}
          href2={urlConfirmity2}
          documentConfirm={this.state.isDocumentConfirm}
          onClick={(e) => this.handleOnDocumentConfirmClick(e)}
        />
      </div>
    );
  }
}

export default Profile;
