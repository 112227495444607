import React from "react";
import axios from "axios";
import { Redirect, Link } from "react-router-dom";
import LoadingSkeleton from "../loading-skeleton/loading-skeleton.component";
import "./task-image-slider.style.scss";
import { FcDownload } from "react-icons/fc";
import PopupMessage from "../../components/popup-message/popup-message.component";
import Zoom from "react-medium-image-zoom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

// Constants
import ENDPOINTS from "../../utils/endpoints";

global.Buffer = global.Buffer || require("buffer").Buffer;

class taskimagesslider extends React.Component {
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      serviceData: [],
      showEmptyState: false,
      thistaskId: this.props.match.params.taskId,
      userData: this.props.location.search
        ? this.props.location.search
        : JSON.parse(localStorage.getItem("task")).userData,
      userBasicToken: "",
      redirect: false,
      showFilters: false,
      filtersTitle: "Ver Filtros",
      idInputValue: "",
      filteredArray: [],
      selectedStageOption: "",
      stageOptions: [],
      selectedDateOption: "",
      dateOptions: [],
      currentPage: 1,
      token: false,
      helperId: false,
    };

    localStorage.setItem("task", JSON.stringify(this.state));
  }

  controller = new AbortController();

  handlePageChange = (page, e) => {
    this.setState({
      currentPage: page,
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  decryptUserData() {
    const decryptedStr = this.handleHash(this.state.userData);
    const passwordArr = decryptedStr.split("password=");
    const pass = passwordArr[1];
    const usernameArr = passwordArr[0].split("username=");
    const user = usernameArr[1].slice(0, -1);

    return [user, pass];
  }

  handleHash(hash) {
    try {
      return atob(hash.substr(1));
    } catch (e) {
      this.setState({ redirect: true });
    }
  }

  loginCheckAPI(urlLoginCheckAPI, userObject) {
    axios.post(urlLoginCheckAPI, userObject).then(
      (result) => {
        this.setState({ token: result.data.token });

        const preparedConfig = {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
            accept: "application/json",
          },
        };

        const urlGetHelperId = ENDPOINTS.userMe;
        this.getHelperId(urlGetHelperId, preparedConfig);
      },
      (error) => {
        window.location.href = process.env.REACT_APP_WEB_LOGIN_URL;
      }
    );
  }

  getHelperId(urlGetHelperId, preparedConfig) {
    axios.get(urlGetHelperId, preparedConfig).then(
      (result) => {
        this.setState({
          helperId: result.data.UsersCode,
        });

        if (!result.data.helper_active) {
          this.setState({ redirect: true });
        }

        localStorage.setItem("taskList", JSON.stringify(this.state));

        this.setState({
          userBasicToken: result.data.auth_content,
        });

        const urlGetTaskImages = ENDPOINTS.general.binary;
        this.getTaksImages(urlGetTaskImages, preparedConfig);
      },
      (error) => {
        console.log(error);
        this.setState({ redirect: true });
      }
    );
  }

  componentDidMount() {
    let secureArray = this.decryptUserData();

    const userObject = {
      username: secureArray[0],
      password: secureArray[1],
    };
    const urlLoginCheckAPI = ENDPOINTS.urlLoginCheckAPI;

    this.loginCheckAPI(urlLoginCheckAPI, userObject, secureArray);
  }

  componentWillUnmount() {
    this.signal.cancel("API is being canceled");
    this.controller.abort();
  }

  getTaksImages(urlGetHelperTasks, preparedConfig) {
    axios
      .get(urlGetHelperTasks, {
        params: {
          MultihelpersJobUid: this.props.match.params.MultihelpersJobUid,
          BinaryTypeUid: "100B0B47-F36B-1410-8FBE-005CABA086B5",
        },
        ...preparedConfig,
      })
      .then(
        (result) => {
          this.setState({
            serviceData: result.data,
          });

          if (result.data.length == 0) {
            this.setState({
              showEmptyState: true,
            });
          } else {
            this.state.serviceData &&
              this.state.serviceData.length &&
              result.data.forEach((image) => {
                this.getTaksImagesAutenticated(image.BinaryUrl);
              });
          }
        },
        (error) => {
          this.setState({ redirect: true });
        }
      );
  }

  getTaksImagesAutenticated(url) {
    const preparedConfig = {
      headers: { Authorization: `Basic ${this.state.userBasicToken}` },
      responseType: "arraybuffer",
    };
    axios.get(url, preparedConfig).then(
      (result) => {
        let base64data = Buffer.from(result.data, "binary").toString("base64");
        let mimeType = result.headers["content-type"];
        let images = [...this.state.serviceData].map((image) => {
          if (image.BinaryUrl == url) {
            image.src = `data:${mimeType};base64,${base64data}`;
          }
          return image;
        });
        this.setState({
          serviceData: images,
        });
      },
      (error) => {
        return;
      }
    );
  }

  forceDownload(url, fileName) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.setRequestHeader("Authorization", "Basic " + this.state.userBasicToken);
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement("a");
      tag.href = imageUrl;
      tag.download = fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  }

  removeArrDuplicates = (originalArray, prop) => {
    let newArray = [];
    let lookupObject = {};

    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }

    return newArray;
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={{ pathname: "/" }} />;
    }
    if (this.state.showEmptyState) {
      PopupMessage(
        "info",
        "¡Vaya! parece que aún no tenemos imágenes",
        "none",
        "servicios"
      );
    } else if (
      typeof this.state.serviceData === "undefined" ||
      this.state.serviceData.length === 0 ||
      this.state.serviceData.filter((data) => !data.BinaryUrl).length > 0
    ) {
      return <LoadingSkeleton />;
    }
    return (
      <div>
        <Link
          className="goBack"
          to={{
            pathname: "/" + this.state.thistaskId,
            token: this.state.token,
            helperId: this.state.helperId,
          }}
        >
          <span>
            <FontAwesomeIcon icon={faArrowLeft} />
          </span>
        </Link>
        <div className="gallery">
          {this.state.serviceData.map((image, i) => {
            if (image.BinaryUrl) {
              return (
                <div className="gallery gallery__container">
                  <Zoom>
                    <img
                      className="gallery gallery__image"
                      key={i}
                      src={image.BinaryUrl}
                    ></img>
                  </Zoom>
                  <div className="download-image">
                    <a
                      download={`${String(i + 1).padStart(2, 0)}${
                        this.state.thistaskId
                      }_${moment().format("DD-MM-YYYY")}`}
                      href={image.BinaryUrl}
                    >
                      <FcDownload />
                    </a>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }
}

export default taskimagesslider;
