import React from "react";
import axios from "axios";

import LoadingSkeleton from "../loading-skeleton/loading-skeleton.component";

import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./create-budget.styles.scss";
import { Card, Row, Col, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import RowComponent from "./row.component";
import PopupMessageWithoutRedirect from "../popup-message/popup-message-without-redirect.component";
import GenericAsyncPopupComponent from "../popup-message/generic-async-popup.component";
import moment from "moment";
import PopupWithSelectBox from "../popup-message/popup-with-select-box.component";

// Constants
import ENDPOINTS from "../../utils/endpoints";

class task extends React.Component {
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);

    this.state = {
      serviceData: [],
      hasImages: false,
      thistaskId: this.props.match.params.taskId,
      thisTaskcp: "",
      userData: this.props.location.search
        ? this.props.location.search
        : JSON.parse(localStorage.getItem("task")).userData,
      redirect: false,
      showFilters: false,
      filtersTitle: "Ver Filtros",
      idInputValue: "",
      thisClient: [],
      filteredArray: [],
      selectedStageOption: "",
      stageOptions: [],
      selectedDateOption: "",
      dateOptions: [],
      currentPage: 1,
      uploadToken: "",
      invoice: "loading",
      token: false,
      helperId: false,
      taskBudgetUrl: "",
      budget: "",
      number_of_rows: 3,
      tipo: this.props.match.params.tipo,
      preparedConfig: {},
      duplicatedid: null,
      reason: "",
    };

    localStorage.setItem("task", JSON.stringify(this.state));

    if (
      this.state.tipo == "4" ||
      this.state.tipo == "5" ||
      this.state.tipo == "6"
    ) {
      let options = {
        "Requiere horas adicionales": "Requiere horas adicionales",
        "Presupuesto de materiales": "Presupuesto de materiales",
        "Trabajos no cubiertos en Brico": "Trabajos no cubiertos en Brico",
      };

      if (this.state.tipo == "5") {
        options = {
          "Serivicio mal presupuestado": "Serivicio mal presupuestado",
          "Presupuesto de materiales": "Presupuesto de materiales",
          "Cliente quiere hacer más cosas": "Cliente quiere hacer más cosas",
        };
      }

      if (this.state.tipo == "6") {
        options = {
          "Requiere horas adicionales a las contratadas":
            "Requiere horas adicionales a las contratadas",
          "Presupuesto de materiales": "Presupuesto de materiales",
          "No corresponde a la descripción del servicio":
            "No corresponde a la descripción del servicio",
        };
      }

      PopupWithSelectBox(
        "question",
        "Indique el motivo",
        this.state.thistaskId,
        "/" + this.state.thistaskId,
        "Confirmar",
        "Cancelar",
        options,
        "Seleccione una opción",
        (reason) => {
          this.state.reason = reason;
        }
      );
    }
  }

  controller = new AbortController();

  handlePageChange = (page, e) => {
    this.setState({
      currentPage: page,
    });
  };

  decryptUserData() {
    const decryptedStr = this.handleHash(this.state.userData);
    const passwordArr = decryptedStr.split("password=");
    const pass = passwordArr[1];
    const usernameArr = passwordArr[0].split("username=");
    const user = usernameArr[1].slice(0, -1);

    return [user, pass];
  }

  handleHash(hash) {
    try {
      return atob(hash.substr(1));
    } catch (e) {
      this.setState({ redirect: true });
    }
  }

  loginCheckAPI(urlLoginCheckAPI, userObject, secureArray) {
    axios.post(urlLoginCheckAPI, userObject).then(
      (result) => {
        this.setState({ token: result.data.token });

        const preparedConfig = {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
            accept: "application/json",
          },
        };

        this.setState({ preparedConfig: preparedConfig });

        const urlGetHelperId = ENDPOINTS.userMe;
        this.getHelperId(urlGetHelperId, preparedConfig, secureArray);
      },
      (error) => {
        window.location.href = process.env.REACT_APP_WEB_LOGIN_URL;
      }
    );
  }

  getHelperId(urlGetHelperId, preparedConfig, secureArray) {
    axios.get(urlGetHelperId, preparedConfig).then(
      (result) => {
        this.setState({
          helperId: result.data.UsersCode,
        });

        if (!result.data.helper_active) {
          this.setState({ redirect: true });
        }

        this.setState({
          userBasicToken: result.data.auth_content,
        });

        localStorage.setItem("taskList", JSON.stringify(this.state));

        const urlGetHelperTasks =
          ENDPOINTS.services.serviceData + this.state.thistaskId;

        this.getTask(urlGetHelperTasks, preparedConfig);
      },
      (error) => {
        this.setState({ redirect: true });
      }
    );
  }

  componentDidMount() {
    let secureArray = this.decryptUserData();

    const userObject = {
      username: secureArray[0],
      password: secureArray[1],
    };
    const urlLoginCheckAPI = ENDPOINTS.urlLoginCheckAPI;

    this.loginCheckAPI(urlLoginCheckAPI, userObject, secureArray);
  }

  componentWillUnmount() {
    this.signal.cancel("API is being canceled");
    this.controller.abort();
  }

  getTask(urlGetHelperTasks, preparedConfig) {
    axios.get(urlGetHelperTasks, preparedConfig).then(
      (result) => {
        this.setState({
          serviceData: result.data,
          hasImages: result.data.images.length,
        });
      },
      (error) => {
        this.setState({ redirect: true });
      }
    );
  }

  addRow() {
    this.setState({
      number_of_rows: this.state.number_of_rows + 1,
    });
  }

  calculateTotal = () => {
    let total = 0.0;
    for (let i = 0; i < this.state.number_of_rows; i += 1) {
      if (parseFloat(document.getElementById("price-" + (i + 1)).value)) {
        total += parseFloat(document.getElementById("price-" + (i + 1)).value);
      }
    }
    if (total != 0.0) {
      document.getElementById("total").value = total;
    } else {
      document.getElementById("total").value = "";
    }
  };

  createBudgetPost(budget_data, funct) {
    axios
      .post(ENDPOINTS.budget.create, budget_data, this.state.preparedConfig)
      .then(
        (result) => {
          funct(result);
        },
        (error) => {
          GenericAsyncPopupComponent(
            "error",
            "No se ha podido registrar el presupuesto. Intentelo mas tarde.",
            false,
            false,
            true,
            "",
            "",
            "mh-modal",
            (result) => {
              //window.location = '/'+this.state.thistaskId;
            }
          );
        }
      );
  }

  postUpdateCreateBudget(data, funct) {
    axios
      .post(ENDPOINTS.budget.postProcess, data, this.state.preparedConfig)
      .then(
        (result) => {
          funct(result);
        },
        (error) => {
          GenericAsyncPopupComponent(
            "error",
            "Se ha resgistrado el presupuesto con errores, contacte con Multihelpers",
            false,
            false,
            true,
            "",
            "",
            "mh-modal",
            (result) => {
              window.location = "/" + this.state.thistaskId;
            }
          );
        }
      );
  }

  duplicateJob(taskId, funct) {
    let url = ENDPOINTS.services.duplicate + taskId;
    axios.get(url, this.state.preparedConfig).then(
      (result) => {
        funct(result);
      },
      (error) => {
        GenericAsyncPopupComponent(
          "error",
          "No se ha podido registrar el presupuesto. Intentelo mas tarde.",
          false,
          false,
          true,
          "",
          "",
          "mh-modal",
          (result) => {
            //window.location = '/'+this.state.thistaskId;
          }
        );
      }
    );
  }

  sendBudget() {
    // Getting concepts and prices
    let concepts = [];
    let concept = {};
    let concept_description = "";
    let concept_price_valoration = 0.0;

    for (let i = 1; i <= this.state.number_of_rows; i = i + 1) {
      if (document.getElementById("concept-" + i).value != "") {
        concept_description = document.getElementById("concept-" + i).value;
        if (document.getElementById("price-" + i).value == "") {
          PopupMessageWithoutRedirect(
            "error",
            "Se ha definido el concepto " + i + " pero no su valór."
          );

          // Exiting function execution
          return;
        }

        concept_price_valoration = document.getElementById("price-" + i).value;

        concept = {
          concept: concept_description,
          price: parseFloat(concept_price_valoration),
          commissionPercentage: 20,
          discountPercentage: 0,
          isDisaggregatedPrice: false,
        };

        concepts.push(concept);
      }
    }

    if (concepts.length == 0) {
      PopupMessageWithoutRedirect(
        "error",
        "No ha definido ni un solo concepto"
      );

      // Exiting function execution
      return;
    }

    let data_to_create_budget = {
      task: this.state.thistaskId,
      concepts: concepts,
      budgetedForHours: false,
      notes: document.getElementById("observations").value,
      isFavorite: false,
      isSent: false,
      helper: this.state.helperId,
      expirationDate: moment(
        new Date().setDate(new Date().getDate() + 15)
      ).format("YYYY-MM-DD HH:mm:ss"),
      sirenaSendBudget: false,
    };

    switch (this.state.tipo) {
      case "0":
      case "1":
      case "2":
        // Create budget and do postprocess
        this.createBudgetPost(data_to_create_budget, (result) => {
          this.StandarProcedure(result);
        });
        break;
      case "3":
        this.createBudgetPost(data_to_create_budget, (result) => {
          this.processOfState3(result, data_to_create_budget);
        });
        break;
      case "4":
      case "5":
      case "6":
        this.duplicateJob(this.state.thistaskId, (result) => {
          this.processOfState3AfterDuplicate(result, data_to_create_budget);
        });
        break;
    }
  }

  // Procedure of state 0, 1, 2 ----------------------------------------------------------

  StandarProcedure(result) {
    let data = {
      BudgetCode: result.data.BudgetCode,
      state: this.state.tipo,
    };

    let post_update_done_successfully = (result) => {
      GenericAsyncPopupComponent(
        "success",
        "Se ha registrado el presupuesto correctamente y se ha enviado al cliente",
        false,
        false,
        true,
        "",
        "",
        "mh-modal",
        (result) => {
          window.location = "/servicios";
        }
      );
    };

    this.postUpdateCreateBudget(data, post_update_done_successfully);
  }

  // Procedure of state 3 -----------------------------------------------------------------

  processOfState3(result, data_to_create_budget) {
    this.duplicateJob(this.state.thistaskId, (result) => {
      this.processOfState3AfterDuplicate(result, data_to_create_budget);
    });
  }

  processOfState3AfterDuplicate(result, data_to_create_budget) {
    data_to_create_budget["MultihelpersJobCode"] = result.data;
    data_to_create_budget["isFavorite"] = true;
    data_to_create_budget["isSent"] = true;

    this.setState({ duplicatedid: result.data });

    this.createBudgetPost(
      data_to_create_budget,
      (result,
      (result) => {
        this.processOfState3AfterCreateBudgetOnDuplicatedJob(
          result,
          data_to_create_budget
        );
      })
    );
  }

  processOfState3AfterCreateBudgetOnDuplicatedJob(
    result,
    data_to_create_budget
  ) {
    let data = {
      BudgetCode: result.data.BudgetCode,
      state: this.state.tipo,
      originalJobId: this.state.thistaskId,
      BricoReason: this.state.reason,
    };

    this.postUpdateCreateBudget(data, (result) => {
      GenericAsyncPopupComponent(
        "success",
        "Se ha registrado el presupuesto correctamente y se ha enviado al cliente",
        false,
        false,
        true,
        "",
        "",
        "mh-modal",
        (result) => {
          window.location = "/servicios";
        }
      );
    });
  }

  render() {
    const rows = [];

    for (let i = 0; i < this.state.number_of_rows; i += 1) {
      rows.push(
        <RowComponent
          element_number={i + 1}
          calculateTotal={this.calculateTotal}
        />
      );
    }

    if (this.state.redirect) {
      return <Redirect to={{ pathname: "/" }} />;
    }

    if (
      typeof this.state.serviceData === "undefined" ||
      this.state.serviceData.length === 0
    ) {
      return <LoadingSkeleton />;
    }

    var DisplayData = {
      id: this.state.thistaskId,
    };

    let redirect = "/servicios";

    switch (this.state.tipo) {
      case "0":
      case "1":
        redirect = "/visita/" + this.state.thistaskId;
        break;
      case "2":
        redirect = "/" + this.state.thistaskId + "/agendar_visita";
        break;
      default:
        redirect = "/servicios";
    }

    return (
      <div className="main-container">
        <Container>
          <Row className="title-row">
            <Col xs={2}>
              <Link
                className="customGoBack"
                to={{
                  pathname: redirect,
                  token: this.state.token,
                  helperId: this.state.helperId,
                }}
              >
                <span>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </span>
              </Link>
            </Col>
            <Col xs={10}>
              <h4 className="main-title">
                Presupuesta el servicio {DisplayData.id}
              </h4>
            </Col>
          </Row>
          <Row className="subtitle-row">
            <p>
              Indica los trabajos a realizar y detalla los materiales incluidos.
              Recuerda, los presupuestos mas claros y desglosados son los más
              contratados y aceptados por los clientes.
            </p>
          </Row>
        </Container>

        <Container className="container-form-inputs pb-0">
          <Row className="mb-1">
            <Col xs={9} style={{ textAlign: "left" }}>
              Conceptos
            </Col>
            <Col xs={3}>Precio SIN IVA</Col>
          </Row>
          {rows}
        </Container>

        <Container className="container-form-footer pt-0">
          <Row className="mb-3">
            <Col xs={9} style={{ textAlign: "left" }}>
              <a
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => {
                  this.addRow();
                }}
              >
                Añadir otro concepto
              </a>
            </Col>
            <Col xs={3}>
              <input
                type="number"
                style={{ width: "100%" }}
                className="input-number-row"
                placeholder="Total"
                id="total"
                readOnly
              />
            </Col>
          </Row>
          <p
            style={{
              textAlign: "left",
              marginTop: "1rem",
              marginBottom: "0.3rem",
            }}
          >
            Observaciones:
          </p>
          <p>
            <textarea
              id="observations"
              style={{
                width: "100%",
                resize: "none",
                height: "7rem",
              }}
            ></textarea>
          </p>
          <button
            className="send-budget-button"
            onClick={() => {
              this.sendBudget();
            }}
          >
            Enviar presupuesto
          </button>
        </Container>
      </div>
    );
  }
}

export default task;
