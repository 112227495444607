import React from "react";
import './task.styles.scss';
import axios from 'axios';
import GenericAsyncPopupComponent from "../popup-message/generic-async-popup.component";
import GenericPopupWithSelectBox from "../popup-message/generic-popup-with-select-box.component";
import GenericPopupWithTextarea from "../popup-message/generic-popup-with-textarea.component";


class ButtonSendGeneralBudget extends React.Component {

    onButtonClick(){

        GenericAsyncPopupComponent(
            'info',
            'Recuerda que no se puede mandar un presupuesto adicional por el mismo trabajo. Solo puedes cobrar materiales, horas adicionales o servicios no incluidos.',
            true,
            true,
            false,
            'Aceptar',
            'Cancelar',
            'mh-modal3',
            (result)=>{
                if(result.value) {
                    window.location = '/'+this.props.taskId+'/presupuestar/6'
                }
            }
        )
    }

    render() {

        return(
            <button
                className="button-documentos custom-button ficha-button-documentos"
                onClick={()=>{this.onButtonClick()}}
            >
                Solicitar pago adicional al cliente
            </button>
        )
    }
}

export default ButtonSendGeneralBudget;