import React from 'react';
import CustomButton from '../custom-button/custom-button.component';
import * as PropTypes from 'prop-types';
import { Row, Col,Container} from "react-bootstrap";
import './conformity.scss';



const ConfirmityDocButton = (props) => {
  return <div>
    <p className='p-line'>
    <a href={props.href}>
            <CustomButton
              className={" conformity-button custom-button " +
                (props.photosAfter ? " " : "grey-button ") +
                (props.documentConfirm ? " green-button" : "")
              }
              onClick={props.onClick}
            >
              Firmar documento conformidad
            </CustomButton>
    </a>
      <a href={props.href2}>
        <CustomButton
            className={" conformity-button custom-button " +
                (props.photosAfter ? " " : "grey-button ") +
                (props.documentConfirm ? " green-button" : "")
            }
            onClick={props.onClick}
        >
          Subir documento conformidad
        </CustomButton>
      </a>
    </p>
  </div>;
};

ConfirmityDocButton.propTypes = {
  href: PropTypes.string,
  href2 : PropTypes.string,
  photosAfter: PropTypes.any,
  documentConfirm: PropTypes.any,
  onClick: PropTypes.func
};

export default ConfirmityDocButton;
