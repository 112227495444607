import React from "react";
import "./button.style.scss"
export default function Button(props) {
  return (
    <button
      className="custom-button"
      style={props.style}
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
}
