import React from "react";
import "./task.styles.scss";
import axios from "axios";
import GenericAsyncPopupComponent from "../popup-message/generic-async-popup.component";
import GenericPopupWithSelectBox from "../popup-message/generic-popup-with-select-box.component";
import GenericPopupWithTextarea from "../popup-message/generic-popup-with-textarea.component";

import ENDPOINTS from "../../utils/endpoints";

class ButtonDeliverDiagnostic extends React.Component {
  onButtonClick() {
    GenericAsyncPopupComponent(
      "info",
      "Recuerda que es importante detallar lo averiguado en el diagnóstico para que el cliente lo entienda.",
      true,
      true,
      false,
      "Aceptar",
      "Rechazar",
      "mh-modal3",
      (result) => {
        if (result.value) {
          this.acceptDoDiagnostic();
        }
      }
    );
  }

  acceptDoDiagnostic() {
    GenericPopupWithSelectBox(
      "question",
      "Indica que se ha determinado en el diagnóstico ",
      "Confirmar",
      "Cancelar",
      {
        1: "No se ha podido localizar avería",
        2: "Entregar presupuesto ",
        3: "No se puede reparar",
      },
      "Seleccione una opción",
      "mh-modal3",
      (reason) => {
        switch (reason.value) {
          case "1":
            this.cantFindMalfunctionOrCantRepair(0, "");
            break;
          case "2":
            window.location = "/" + this.props.taskId + "/presupuestar/3";
            break;
          case "3":
            this.askReasonCantRepair();
            break;
        }
      }
    );
  }

  cantFindMalfunctionOrCantRepair(state, text) {
    let data = {
      MultihelpersJobCode: this.props.taskId,
      state: state,
      text: text,
    };

    let url = ENDPOINTS.services.cantFindMalfunctionOrCantRepair;
    axios.post(url, data, this.props.preparedConfig).then(
      (result) => {
        GenericAsyncPopupComponent(
          "success",
          "Se ha actualziado correctamente la oferta",
          false,
          false,
          true,
          "",
          "",
          "mh-modal",
          (result) => {
            window.location = "/servicios";
          }
        );
      },
      (error) => {
        GenericAsyncPopupComponent(
          "error",
          "No se ha podido actualizar la oferta, intentelo mas tarde.",
          false,
          false,
          true,
          "",
          "",
          "mh-modal",
          (result) => {}
        );
      }
    );
  }

  askReasonCantRepair() {
    GenericPopupWithTextarea(
      "question",
      "Indica los motivos:",
      "Confirmar",
      "Cancelar",
      "mh-modal3",
      (result) => {
        if (result.value) {
          this.cantFindMalfunctionOrCantRepair(1, result.value);
        }
      }
    );
  }

  render() {
    return (
      <button
        className="custom-button custom-button1"
        onClick={() => {
          this.onButtonClick();
        }}
      >
        Entregar diagnóstico
      </button>
    );
  }
}

export default ButtonDeliverDiagnostic;
