import React from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import Pagination from "react-paginating";
import LoadingSkeleton from "../../loading-skeleton/loading-skeleton.component";
import { Row, Col, Container } from "react-bootstrap";

// Constants
import ENDPOINTS from "../../../utils/endpoints";

import "./finished-list.styles.scss";

class FinishedListComponent extends React.Component {
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);

    if (
      this.props.location.search == "" &&
      JSON.parse(localStorage.getItem("task"))?.userData == null
    ) {
      window.location.href = process.env.REACT_APP_WEB_LOGIN_URL;
    }

    this.state = {
      serviceData: [],
      userData: this.props.location.search
        ? this.props.location.search
        : JSON.parse(localStorage.getItem("task")).userData,
      redirect: false,
      showFilters: false,
      filtersTitle: "Ver Filtros",
      idInputValue: "",
      filteredArray: [],
      selectedStageOption: "",
      stageOptions: [],
      selectedAssignedDateOption: "",
      AssignedDateOptions: [],
      selectedFinishedDateOption: "",
      FinishedDateOptions: [],
      currentPage: 1,
      token: false,
      helperId: false,
    };

    localStorage.setItem("task", JSON.stringify(this.state));

    this.handleFilters = this.handleFilters.bind(this);
  }

  controller = new AbortController();

  handlePageChange = (page, e) => {
    this.setState({
      currentPage: page,
    });
  };

  decryptUserData() {
    const decryptedStr = this.handleHash(this.state.userData);
    const passwordArr = decryptedStr.split("password=");
    const pass = passwordArr[1];
    const usernameArr = passwordArr[0].split("username=");
    const user = usernameArr[1].slice(0, -1);

    return [user, pass];
  }

  handleHash(hash) {
    try {
      return atob(hash.substr(1));
    } catch (e) {
      console.log("Error en Handle hash");
      this.setState({ redirect: true });
    }
  }

  loginCheckAPI(urlLoginCheckAPI, userObject) {
    axios.post(urlLoginCheckAPI, userObject).then(
      (result) => {
        this.setState({ token: result.data.token });

        const preparedConfig = {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
            accept: "application/json",
          },
        };

        this.getHelperId(ENDPOINTS.userMe, preparedConfig);
      },
      (error) => {
        window.location.href = process.env.REACT_APP_WEB_LOGIN_URL;
      }
    );
  }

  getHelperId(urlGetHelperId, preparedConfig) {
    axios.get(urlGetHelperId, preparedConfig).then(
      (result) => {
        this.setState({
          helperId: result.data.UsersCode,
        });

        if (!result.data.helper_active) {
          this.setState({ redirect: true });
        }

        localStorage.setItem("taskList", JSON.stringify(this.state));

        const urlGetHelperTasks = ENDPOINTS.services.finished_list;

        this.getHelperTasks(urlGetHelperTasks, preparedConfig);
      },
      (error) => {
        console.log("Error en get helper api");
        this.setState({ redirect: true });
      }
    );
  }

  componentDidMount() {
    let secureArray = this.decryptUserData();
    const userObject = {
      username: secureArray[0],
      password: secureArray[1],
    };
    const urlLoginCheckAPI = ENDPOINTS.urlLoginCheckAPI;

    this.loginCheckAPI(urlLoginCheckAPI, userObject, secureArray);
  }

  componentWillUnmount() {
    this.signal.cancel("API is being canceled");
    this.controller.abort();
  }

  getHelperTasks(urlGetHelperTasks, preparedConfig) {
    axios.post(urlGetHelperTasks, {}, preparedConfig).then(
      (result) => {
        this.setState({
          serviceData: result.data.data,
          filteredArray: this.paginatorNormalizer(result.data.data),
          stageOptions: this.stageOptionsNormalizer(result.data.data),
          AssignedDateOptions: this.assignedDateOptionsNormalizer(
            result.data.data
          ),
          FinishedDateOptions: this.finishedDateOptionsNormalizer(
            result.data.data
          ),
        });
      },
      (error) => {
        console.log("Error en get helper tasks");
        this.setState({ redirect: true });
      }
    );
  }

  goToTask(taskId) {
    localStorage.setItem("back_to", "/servicios_finalizados");
    window.location = "/" + taskId;
  }

  createContext = (serviceDataArray) => {
    let context = [];
    for (let taskObj of serviceDataArray) {
      context.push(
        <div
          className="task-list-row"
          key={taskObj.MultihelpersJobCode}
          onClick={() => {
            this.goToTask(taskObj.MultihelpersJobCode);
          }}
        >
          <Container className="container-row">
            <Row>
              <Col sm={12} md={true} className="first-col">
                {taskObj.MultihelpersJobCode}
              </Col>
              <Col sm={12} md={true} className="default-col">
                {taskObj.PostalCodeZip}
              </Col>
              <Col sm={12} md={true} className="default-col">
                {taskObj.ExtraUserDataFirstName}
              </Col>
              <Col sm={12} md={true} className="default-col">
                {taskObj.CategoryName}
              </Col>
              <Col sm={12} md={true} className="default-col">
                {taskObj.IsPaid == "1" ? "Pagado" : "No pagado"}
              </Col>
              <Col sm={12} md={true} className="default-col">
                {taskObj.AssignationDate
                  ? new Date(taskObj.AssignationDate).toLocaleDateString(
                      "es-ES",
                      { year: "numeric", month: "2-digit", day: "2-digit" }
                    )
                  : "-"}
              </Col>
              <Col sm={12} md={true} className="default-col">
                {taskObj.ExecutionDate
                  ? new Date(taskObj.ExecutionDate).toLocaleDateString(
                      "es-ES",
                      { year: "numeric", month: "2-digit", day: "2-digit" }
                    )
                  : "-"}
              </Col>
              <Col sm={12} md={true} className="default-col">
                {taskObj.FinishedAt
                  ? new Date(taskObj.FinishedAt).toLocaleDateString("es-ES", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })
                  : "-"}
              </Col>
            </Row>
          </Container>
        </div>
      );
    }

    return context.reverse();
  };

  handleFilters = () => {
    const { showFilters } = this.state;
    this.setState({
      showFilters: !showFilters,
      filtersTitle: !this.state.showFilters ? "Ocultar Filtros" : "Ver Filtros",
    });
  };

  filterServiceId = (e) => {
    let currentList = [];
    let newList = [];

    if (e.target.value !== "") {
      let value = e.target.value;
      currentList = this.state.serviceData;

      newList = currentList.filter((item) => {
        return String(item.MultihelpersJobCode).includes(value);
      });
    } else {
      newList = this.state.serviceData;
    }
    this.setState({
      idInputValue: e.target.value,
      filteredArray: this.paginatorNormalizer(newList),
    });
  };

  filterStage = (selectedStageOption) => {
    let currentList = [];
    let newList = [];

    if (selectedStageOption !== "") {
      currentList = this.state.serviceData;
      newList = currentList.filter((item) => {
        return item.IsPaid === selectedStageOption.value;
      });
    }

    this.setState({
      filteredArray: this.paginatorNormalizer(newList),
      selectedStageOption: selectedStageOption,
    });
  };

  filterByAssignedDate = (selectedAssignedDateOption) => {
    let currentList = [];
    let newList = [];

    if (selectedAssignedDateOption !== "") {
      currentList = this.state.serviceData;
      newList = currentList.filter((item) => {
        return item.AssignationDate === selectedAssignedDateOption.value;
      });
    }

    this.setState({
      filteredArray: this.paginatorNormalizer(newList),
      selectedAssignedDateOption: selectedAssignedDateOption,
    });
  };

  filterByFinishedDate = (selectedFinishedDateOption) => {
    let currentList = [];
    let newList = [];

    if (selectedFinishedDateOption !== "") {
      currentList = this.state.serviceData;
      newList = currentList.filter((item) => {
        return item.FinishedAt === selectedFinishedDateOption.value;
      });
    }

    this.setState({
      filteredArray: this.paginatorNormalizer(newList),
      selectedFinishedDateOption: selectedFinishedDateOption,
    });
  };

  paginatorNormalizer = (serviceDataArr) => {
    let contextCopy = this.createContext(serviceDataArr);
    let paginatedContext = [];
    while (contextCopy.length > 0) {
      // TODO change delete count for the number of tasks per page
      let a = contextCopy.splice(0, 8);
      paginatedContext.push(a);
    }
    return paginatedContext;
  };

  stageOptionsNormalizer = (serviceDataArr) => {
    let resultArr = [];

    resultArr.push({
      value: "1",
      label: "Pagado",
    });

    resultArr.push({
      value: "0",
      label: "No pagado",
    });

    return resultArr;
  };

  assignedDateOptionsNormalizer = (serviceDataArr) => {
    let resultArray = [];
    for (let element of serviceDataArr) {
      resultArray.push({
        value: element.AssignationDate,
        label: element.AssignationDate
          ? new Date(element.AssignationDate).toLocaleDateString("es-ES", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
          : "-",
      });
    }
    resultArray = this.removeArrDuplicates(resultArray, "value");

    return resultArray;
  };

  finishedDateOptionsNormalizer = (serviceDataArr) => {
    let resultArray = [];
    for (let element of serviceDataArr) {
      resultArray.push({
        value: element.FinishedAt,
        label: element.FinishedAt
          ? new Date(element.FinishedAt).toLocaleDateString("es-ES", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
          : "-",
      });
    }
    resultArray = this.removeArrDuplicates(resultArray, "value");

    return resultArray;
  };

  removeArrDuplicates = (originalArray, prop) => {
    let newArray = [];
    let lookupObject = {};

    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }

    return newArray;
  };

  resetFilters = () => {
    this.setState({
      idInputValue: "",
      selectedStageOption: "",
      selectedAssignedDateOption: "",
      selectedFinishedDateOption: "",
      filteredArray: this.paginatorNormalizer(this.state.serviceData),
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={{ pathname: "/" }} />;
    }

    if (
      typeof this.state.serviceData === "undefined" ||
      this.state.serviceData.length === 0
    ) {
      return <LoadingSkeleton />;
    }

    const limit = 2;
    const pageCount = 3;
    const {
      currentPage,
      showFilters,
      selectedStageOption,
      selectedAssignedDateOption,
      selectedFinishedDateOption,
    } = this.state;
    const total = this.state.filteredArray.length * limit;

    return (
      <div>
        <span className="task-filter" onClick={this.handleFilters}>
          {this.state.filtersTitle}
        </span>
        {showFilters && (
          <div className="filter-section">
            <div className="filter-by-id">
              <label htmlFor="filterById">ID servicio:&nbsp;</label>
              <input
                type="tel"
                pattern="^-?[0-9]\d*\.?\d*$"
                id="filterById"
                value={this.state.idInputValue}
                onChange={this.filterServiceId}
              />
            </div>
            <div className="filter-by-stage">
              <label>Categoría:&nbsp;</label>
              <Select
                className="stage-select-container"
                classNamePrefix="stage-select"
                placeholder=""
                value={selectedStageOption}
                onChange={this.filterStage}
                options={this.state.stageOptions}
              />
            </div>
            <div className="filter-by-date">
              <label>Fecha del asignación:&nbsp;</label>
              <Select
                className="date-select-container"
                classNamePrefix="date-select"
                placeholder=""
                value={selectedAssignedDateOption}
                onChange={this.filterByAssignedDate}
                options={this.state.AssignedDateOptions}
              />
            </div>

            <div className="filter-by-date">
              <label>Fecha del finalización:&nbsp;</label>
              <Select
                className="date-select-container"
                classNamePrefix="date-select"
                placeholder=""
                value={selectedFinishedDateOption}
                onChange={this.filterByFinishedDate}
                options={this.state.FinishedDateOptions}
              />
            </div>

            <div className="reset-filters" onClick={this.resetFilters}>
              <span>Reestablecer filtros</span>
            </div>
          </div>
        )}
        <div>
          <div className="task-list-row-header ">
            <Container className="container-row">
              <Row>
                <Col sm={12} md={true} className="header-col d-none d-md-block">
                  ID
                </Col>
                <Col sm={12} md={true} className="header-col d-none d-md-block">
                  Código Postal
                </Col>
                <Col sm={12} md={true} className="header-col d-none d-md-block">
                  Nombre
                </Col>
                <Col sm={12} md={true} className="header-col d-none d-md-block">
                  Categoría
                </Col>
                <Col sm={12} md={true} className="header-col d-none d-md-block">
                  Estado
                </Col>
                <Col sm={12} md={true} className="header-col d-none d-md-block">
                  F.Asignación
                </Col>
                <Col sm={12} md={true} className="header-col d-none d-md-block">
                  F.Ejecución
                </Col>
                <Col sm={12} md={true} className="header-col d-none d-md-block">
                  F.Finalización
                </Col>
              </Row>
            </Container>
          </div>
          {this.state.filteredArray[this.state.currentPage - 1]}
        </div>

        <Pagination
          className="bg-mh"
          total={total}
          limit={limit}
          pageCount={pageCount}
          currentPage={currentPage}
        >
          {({
            pages,
            currentPage,
            hasNextPage,
            hasPreviousPage,
            previousPage,
            nextPage,
            totalPages,
            getPageItemProps,
          }) => (
            <div>
              {hasPreviousPage && (
                <button
                  className="paginating-button"
                  {...getPageItemProps({
                    pageValue: previousPage,
                    onPageChange: this.handlePageChange,
                  })}
                >
                  {"<"}
                </button>
              )}

              {pages.map((page) => {
                let activePage = null;
                if (currentPage === page) {
                  activePage = { backgroundColor: "#00cded", color: "#fff" };
                }
                if (this.state.filteredArray.length > 1) {
                  return (
                    <button
                      className="paginating-button"
                      {...getPageItemProps({
                        pageValue: page,
                        key: page,
                        style: activePage,
                        onPageChange: this.handlePageChange,
                      })}
                    >
                      {page}
                    </button>
                  );
                }
              })}

              {hasNextPage && (
                <button
                  className="paginating-button"
                  {...getPageItemProps({
                    pageValue: nextPage,
                    onPageChange: this.handlePageChange,
                  })}
                >
                  {">"}
                </button>
              )}
            </div>
          )}
        </Pagination>
      </div>
    );
  }
}

export default FinishedListComponent;
