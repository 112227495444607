import React from "react";
import "./task.styles.scss";
import GenericAsyncPopupComponent from "../popup-message/generic-async-popup.component";

function onButtonClick(taskId) {
  GenericAsyncPopupComponent(
    "info",
    "Recuerda que en los servicios de manitas, solo puedes cobrar materiales, horas adicionales o gremios no incluidos.",
    true,
    true,
    false,
    "Aceptar",
    "Cancelar",
    "mh-modal3",
    (result) => {
      if (result.value) {
        window.location = "/" + taskId + "/presupuestar/4";
      }
    }
  );
}

function ButtonBricoSendBudget(props) {
  return (
    <button
      className={props.className}
      onClick={() => {
        onButtonClick(props.taskId);
      }}
    >
      Solicitar pago adicional al cliente
    </button>
  );
}

export default ButtonBricoSendBudget;
