import React from "react";
import axios from "axios";
import { Redirect, Link } from "react-router-dom";
import LoadingSkeleton from "../loading-skeleton/loading-skeleton.component";
// Constants
import ENDPOINTS from "../../utils/endpoints";
import "./task-image-slider.style.scss";
import { FcDownload } from "react-icons/fc";
import PopupMessage from "../popup-message/popup-message.component";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

global.Buffer = global.Buffer || require("buffer").Buffer;

class taskavailableimagesslider extends React.Component {
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      serviceData: [],
      showEmptyState: false,
      thistaskId: this.props.match.params.taskId,
      userData: this.props.location.search
        ? this.props.location.search
        : JSON.parse(localStorage.getItem("task")).userData,
      userBasicToken: "",
      redirect: false,
      showFilters: false,
      filtersTitle: "Ver Filtros",
      idInputValue: "",
      filteredArray: [],
      selectedStageOption: "",
      stageOptions: [],
      selectedDateOption: "",
      dateOptions: [],
      currentPage: 1,
      token: false,
      helperId: false,
      clientId: false,
      clientEmail: false,
    };

    localStorage.setItem("task", JSON.stringify(this.state));
  }

  controller = new AbortController();

  handlePageChange = (page, e) => {
    this.setState({
      currentPage: page,
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  decryptUserData() {
    const decryptedStr = this.handleHash(this.state.userData);
    const passwordArr = decryptedStr.split("password=");
    const pass = passwordArr[1];
    const usernameArr = passwordArr[0].split("username=");
    const user = usernameArr[1].slice(0, -1);

    return [user, pass];
  }

  handleHash(hash) {
    try {
      return atob(hash.substr(1));
    } catch (e) {
      this.setState({ redirect: true });
    }
  }

  loginCheckAPI(urlLoginCheckAPI, userObject) {
    axios.post(urlLoginCheckAPI, userObject).then(
      (result) => {
        this.setState({ token: result.data.token });
        const preparedConfig = {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
            accept: "application/json",
          },
        };
        const urlGetHelperId =
          process.env.REACT_APP_API_URL +
          "api/fos_users?email=" +
          userObject.username;
        this.getHelperId(urlGetHelperId, preparedConfig);
      },
      (error) => {
        window.location.href = process.env.REACT_APP_WEB_LOGIN_URL;
      }
    );
  }

  getClientId(urlGetClient, preparedConfig) {
    axios.get(urlGetClient, preparedConfig).then(
      (result) => {
        this.setState({
          clientId: result.data.fosUser,
        });
        const preparedConfig = {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
            accept: "application/json",
          },
        };
        const urlGetClientEmail =
          process.env.REACT_APP_API_URL + result.data.fosUser.substr(1);
        this.getClientEmail(urlGetClientEmail, preparedConfig);
      },
      (error) => {
        console.log(error);
        this.setState({ redirect: true });
      }
    );
  }

  getClientEmail(urlGetClientEmail, preparedConfig) {
    axios.get(urlGetClientEmail, preparedConfig).then(
      (result) => {
        this.setState({
          clientEmail: result.data.email,
        });

        const urlGetTaskImages =
          process.env.REACT_APP_API_URL +
          "api/multihelpersjobs/" +
          this.state.thistaskId +
          "/images";
        this.getTaksImages(urlGetTaskImages, preparedConfig);
      },
      (error) => {
        console.log(error);
        this.setState({ redirect: true });
      }
    );
  }

  getHelperId(urlGetHelperId, preparedConfig) {
    axios.get(urlGetHelperId, preparedConfig).then(
      (result) => {
        this.setState({
          helperId: result.data[0].id,
        });

        const urlIsHelperActiveCheckAPI =
          process.env.REACT_APP_API_URL +
          "api/helpers/" +
          this.state.helperId +
          "/is_helper_active";
        this.getIsHelperActive(urlIsHelperActiveCheckAPI, preparedConfig);
        const urlGetClient =
          process.env.REACT_APP_API_URL +
          "api/multihelpersjobs/" +
          this.state.thistaskId;
        this.getClientId(urlGetClient, preparedConfig);
        localStorage.setItem("taskList", JSON.stringify(this.state));
      },
      (error) => {
        console.log(error);
        this.setState({ redirect: true });
      }
    );
  }

  getIsHelperActive(urlIsHelperActiveCheckAPI, preparedConfig) {
    axios.get(urlIsHelperActiveCheckAPI, preparedConfig).then(
      (result) => {
        if (!result.data) this.setState({ redirect: true });
      },
      (error) => {
        this.setState({ redirect: true });
      }
    );
  }

  componentDidMount() {
    let secureArray = this.decryptUserData();

    const userObject = {
      username: secureArray[0],
      password: secureArray[1],
    };
    const urlLoginCheckAPI = ENDPOINTS.urlLoginCheckAPI;

    this.loginCheckAPI(urlLoginCheckAPI, userObject, secureArray);
  }

  componentWillUnmount() {
    this.signal.cancel("API is being canceled");
    this.controller.abort();
  }

  getTaksImages(urlGetHelperTasks, preparedConfig) {
    axios.get(urlGetHelperTasks, preparedConfig).then(
      (result) => {
        this.setState({
          serviceData: result.data,
        });
        if (result.data.length == 0) {
          this.setState({
            showEmptyState: true,
          });
        } else {
          const urlGetpass =
            process.env.REACT_APP_API_URL +
            this.state.clientId.substr(1) +
            "/encrypted_password";
          const preparedConfig = {
            headers: {
              Authorization: `Bearer ${this.state.token}`,
              accept: "application/json",
            },
          };

          axios.get(urlGetpass, preparedConfig).then((response) => {
            let userData = this.decryptUserData();
            let email = this.state.clientEmail;
            let pwd = response.data?.password;

            let basicToken = btoa(email + ":" + pwd);
            this.setState({
              userBasicToken: basicToken,
            });

            this.state.serviceData &&
              this.state.serviceData.length &&
              result.data.forEach((image) => {
                this.getTaksImagesAutenticated(image.imageUrl);
              });
          });
        }
      },
      (error) => {
        this.setState({ redirect: true });
      }
    );
  }

  getTaksImagesAutenticated(url) {
    const preparedConfig = {
      headers: { Authorization: `Basic ${this.state.userBasicToken}` },
      responseType: "arraybuffer",
    };

    axios.get(url, preparedConfig).then(
      (result) => {
        let base64data = Buffer.from(result.data, "binary").toString("base64");
        let mimeType = result.headers["content-type"];
        let images = [...this.state.serviceData].map((image) => {
          if (image.imageUrl == url) {
            image.src = `data:${mimeType};base64,${base64data}`;
          }

          return image;
        });

        this.setState({
          serviceData: images,
        });
      } /** , error => {
          // this.setState({redirect: true});
        }*/
    );
  }

  forceDownload(url, fileName) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.setRequestHeader("Authorization", "Basic " + this.state.userBasicToken);
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement("a");
      tag.href = imageUrl;
      tag.download = fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  }

  removeArrDuplicates = (originalArray, prop) => {
    let newArray = [];
    let lookupObject = {};

    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }

    return newArray;
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={{ pathname: "/" }} />;
    }
    if (this.state.showEmptyState) {
      PopupMessage(
        "info",
        "¡Vaya! parece que aún no tenemos imágenes",
        "none",
        "servicios"
      );
    } else if (
      typeof this.state.serviceData === "undefined" ||
      this.state.serviceData.length === 0 ||
      this.state.serviceData.filter((data) => !data.imageUrl).length > 0
    ) {
      return <LoadingSkeleton />;
    }
    return (
      <div>
        <Link
          className="goBack"
          to={{
            pathname: "/" + this.state.thistaskId + "/disponible",
            token: this.state.token,
            helperId: this.state.helperId,
          }}
        >
          <span>
            <FontAwesomeIcon icon={faArrowLeft} />
          </span>
        </Link>
        <div className="gallery">
          {this.state.serviceData.map((image, i) => {
            if (image.src) {
              return (
                <div className="gallery gallery__container">
                  <Zoom>
                    <img
                      className="gallery gallery__image"
                      key={i}
                      src={image.src}
                    ></img>
                  </Zoom>
                  <div className="download-image">
                    <a
                      download={`${String(i + 1).padStart(2, 0)}${
                        this.state.thistaskId
                      }_${moment().format("DD-MM-YYYY")}`}
                      href={image.src}
                    >
                      <FcDownload />
                    </a>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }
}

export default taskavailableimagesslider;
