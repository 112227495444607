import React from "react";
import axios from "axios";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import LoadingSkeleton from "../loading-skeleton/loading-skeleton.component";

import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Container } from "react-bootstrap";
import { logUserTypes } from "../../utils/constants";

import PopupConfirmWithoutRedirect from "../popup-message/popup-confirm-without-rediderc.component";

import "./task.styles.scss";
import PopupMessage from "../popup-message/popup-message.component";
import ButtonAssignedSendBudget from "./ButtonAssignedSendBudget.component";
import ButtonDeliverDiagnostic from "../task/ButtonDeliverDiagnostic.component";
import ButtonBricoSendBudget from "../task/ButtonBricoSendBudget.component";
import ButtonRejectService from "../task/ButtonRejectService.component";

import Button from "../inputs/Button/button";
import DelayContact from "../../interactions/delay-contact";

import TaskLog from "../task-log/task-log.component";
import TaskLogCreateModal from "../task-log/task-log-create-modal.component";

// Constants
import ENDPOINTS from "../../utils/endpoints";

class task extends React.Component {
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);

    this.state = {
      serviceData: [],
      hasImages: false,
      thistaskId: this.props.match.params.taskId,
      thisTaskcp: "",
      userData: this.props.location.search
        ? this.props.location.search
        : JSON.parse(localStorage.getItem("task")).userData,
      redirect: false,
      showFilters: false,
      filtersTitle: "Ver Filtros",
      idInputValue: "",
      thisClient: [],
      filteredArray: [],
      selectedStageOption: "",
      stageOptions: [],
      selectedDateOption: "",
      dateOptions: [],
      currentPage: 1,
      uploadToken: "",
      invoice: "loading",
      token: false,
      helperId: false,
      taskBudgetUrl: "",
      budget: "",
      showModalCreateLog: false,
    };

    localStorage.setItem("task", JSON.stringify(this.state));
  }

  controller = new AbortController();

  handlePageChange = (page, e) => {
    this.setState({
      currentPage: page,
    });
  };

  decryptUserData() {
    const decryptedStr = this.handleHash(this.state.userData);
    const passwordArr = decryptedStr.split("password=");
    const pass = passwordArr[1];
    const usernameArr = passwordArr[0].split("username=");
    const user = usernameArr[1].slice(0, -1);

    return [user, pass];
  }

  handleHash(hash) {
    try {
      return atob(hash.substr(1));
    } catch (e) {
      this.setState({ redirect: true });
    }
  }

  loginCheckAPI(urlLoginCheckAPI, userObject, secureArray) {
    axios.post(urlLoginCheckAPI, userObject).then(
      (result) => {
        this.setState({ token: result.data.token });

        const preparedConfig = {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
            accept: "application/json",
          },
        };

        this.state.preparedConfig = preparedConfig;

        const urlGetHelperId = ENDPOINTS.userMe;

        this.getHelperId(urlGetHelperId, preparedConfig, secureArray);
      },
      (error) => {
        window.location.href = process.env.REACT_APP_WEB_LOGIN_URL;
      }
    );
  }

  getHelperId(urlGetHelperId, preparedConfig, secureArray) {
    axios.get(urlGetHelperId, preparedConfig).then(
      (result) => {
        this.setState({
          helperId: result.data.UsersCode,
        });

        if (!result.data.helper_active) {
          this.setState({ redirect: true });
        }

        this.setState({
          userBasicToken: result.data.auth_content,
        });

        localStorage.setItem("taskList", JSON.stringify(this.state));

        const urlGetHelperTasks =
          ENDPOINTS.services.serviceData + this.state.thistaskId;

        this.getHelperTasks(urlGetHelperTasks, preparedConfig);
      },
      (error) => {
        console.log(error);
        this.setState({ redirect: true });
      }
    );
  }

  componentDidMount() {
    let secureArray = this.decryptUserData();

    const userObject = {
      username: secureArray[0],
      password: secureArray[1],
    };
    const urlLoginCheckAPI = ENDPOINTS.urlLoginCheckAPI;

    this.loginCheckAPI(urlLoginCheckAPI, userObject, secureArray);
  }

  componentWillUnmount() {
    this.signal.cancel("API is being canceled");
    this.controller.abort();
  }

  getHelperTasks(urlGetHelperTasks, preparedConfig) {
    axios.get(urlGetHelperTasks, preparedConfig).then(
      (result) => {
        console.log(result);

        this.setState({
          serviceData: result.data,
          hasImages: result.data.images.length,
        });

        const urlGetTaskPostalCode =
          ENDPOINTS.general.cp + this.state.serviceData.postalcode;

        const urlGetClient = ENDPOINTS.general.ExtraUserData;

        const urlGetbudget = ENDPOINTS.general.budget;
        const urlGetLogTask = `${ENDPOINTS.taskLogHelper.list}/${this.state.serviceData.MultihelpersJobUid}`;

        this.getCp(urlGetTaskPostalCode, preparedConfig);
        this.getClient(urlGetClient, preparedConfig);
        this.getTaskBudget(urlGetbudget, preparedConfig);
        this.getLog(urlGetLogTask, preparedConfig);
      },
      (error) => {
        this.setState({ redirect: true });
      }
    );
  }

  getTaskBudget(urlGetbudget, preparedConfig) {
    axios
      .get(urlGetbudget, {
        params: {
          MultihelpersJobUid: this.state.serviceData.MultihelpersJobUid,
          BudgetIsFavorite: "1",
        },
        ...preparedConfig,
      })
      .then((result) => {
        if (result.data.length > 0 && result.data[0].Binary.length > 0) {
          this.setState({
            taskBudgetUrl: result.data[0].Binary[0].BinaryUrl,
          });
        } else {
          this.setState({
            taskBudgetUrl: "SIN_PRESUPUESTO",
          });
        }

        if (this.state.serviceData.helperInvoiceUrl) {
          this.getTaksDocumentsAutenticated(
            this.state.serviceData.helperInvoiceUrl
          );
        } else {
          this.setState({
            invoice: "",
          });
        }
      });
  }

  getTaksDocumentsAutenticated(url) {
    const preparedConfig = {
      headers: { Authorization: `Basic ${this.state.userBasicToken}` },
      responseType: "arraybuffer",
    };
    axios.get(url, preparedConfig).then((result) => {
      let base64data = Buffer.from(result.data, "binary").toString("base64");
      let mimeType = result.headers["content-type"];
      this.setState({ invoice: `data:${mimeType};base64,${base64data}` });
    });
  }

  getCp(urlGetHelperTasks, preparedConfig) {
    axios.get(urlGetHelperTasks, preparedConfig).then(
      (result) => {
        this.setState({
          thisTaskcp: result.data[0].PostalCodeZip,
        });
      },
      (error) => {
        this.setState({ redirect: true });
      }
    );
  }

  getClient(urlGetHelperTasks, preparedConfig) {
    axios
      .get(urlGetHelperTasks, {
        params: { UsersUid: this.state.serviceData.fosUser },
        ...preparedConfig,
      })
      .then(
        (result) => {
          this.setState({
            thisClient: result.data[0],
          });
        },
        (error) => {
          this.setState({ redirect: true });
        }
      );
  }

  removeArrDuplicates = (originalArray, prop) => {
    let newArray = [];
    let lookupObject = {};

    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }

    return newArray;
  };

  forceDownload(url, fileName) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.setRequestHeader("Authorization", "Basic " + this.state.userBasicToken);
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement("a");
      tag.href = imageUrl;
      tag.download = fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  }

  downloadFileOverridingFilename(url, filename) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var blob = this.response;
      var a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = filename;
      a.dispatchEvent(new MouseEvent("click"));
    };
    xhr.send(null);
  }

  formatPhone(phone) {
    if (phone) {
      if (phone.substr(0, 2) == "34") return "+" + phone;
      else if (phone.substr(0, 3) == "+34") return phone;
      else return phone;
    }
  }

  async rejectTask() {
    var response;

    await PopupConfirmWithoutRedirect(
      "question",
      "¿Esta seguro de que quiere rechazar este trabajo?",
      "Si",
      "No",
      "mh-modal2",
      (result) => {
        console.log("result");
        console.log(result);
        response = result["value"];
      }
    );

    if (response) {
      var send = await axios.get(
        ENDPOINTS.services.changeStateAssigned + this.state.thistaskId + "/0",
        this.state.preparedConfig
      );

      axios
        .all([send])
        .then(
          axios.spread((...responses) => {
            PopupMessage(
              "success",
              "Se ha rechazado correctamente el trabajo",
              "none",
              "servicios_asignados"
            );
          })
        )
        .catch((errors) => {
          PopupMessage(
            "error",
            "Algo no va bien",
            "",
            this.state.thistaskId + "/aceptar_rechazar_servicio"
          );
        });
    }
  }

  async proposeAppointment() {
    var response;

    await PopupConfirmWithoutRedirect(
      "question",
      "¿Has llamado al cliente para confirmar?",
      "Si",
      "No",
      "mh-modal3",
      (result) => {
        response = result["value"];
      }
    );

    if (response) {
      // El primer parametro muestra o no el select inicial y el segunda donde se guarda
      window.location = "/" + this.state.thistaskId + "/cambiar_fecha/0/1";
    }
  }

  async acceptTask() {
    var response;

    await PopupConfirmWithoutRedirect(
      "question",
      "¿Esta seguro de que quiere aceptar este trabajo?",
      "Si",
      "No",
      "mh-modal3",
      (result) => {
        response = result["value"];
      }
    );

    if (response) {
      var send = await axios.get(
        ENDPOINTS.services.changeStateAssigned + this.state.thistaskId + "/1",
        this.state.preparedConfig
      );

      axios
        .all([send])
        .then(
          axios.spread((...responses) => {
            PopupMessage(
              "success",
              "Se ha aceptado correctamente el trabajo",
              "none",
              this.state.thistaskId + "/aceptar_rechazar_servicio"
            );
          })
        )
        .catch((errors) => {
          PopupMessage(
            "error",
            "Algo no va bien",
            "",
            this.state.thistaskId + "/aceptar_rechazar_servicio"
          );
        });
    }
  }

  handleCreateLog = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());

    const urlCreateLog = `${ENDPOINTS.taskLogHelper.create}`;

    const preparedConfig = {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
        accept: "application/json",
      },
    };

    axios
      .post(
        urlCreateLog,
        {
          MultihelpersJobUid: this.state.serviceData.MultihelpersJobUid,
          Action: data["log-text-action"],
          MultihelpersJobLogUid: uuidv4(),
        },
        preparedConfig
      )
      .then(
        (result) => {
          this.setState({
            showModalCreateLog: false,
          });

          const urlGetLogTask = `${ENDPOINTS.taskLogHelper.list}/${this.state.serviceData.MultihelpersJobUid}`;
          this.getLog(urlGetLogTask, preparedConfig);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  getLog(urlGetLogTaks, preparedConfig) {
    axios.get(urlGetLogTaks, preparedConfig).then(
      (result) => {
        const results = result.data
          .filter((log) => log.AccessType === "0" || log.AccessType === "1")
          .map((log) => {
            const { updated_at, UserType, ...rest } = log;
            const UserTypeCasted = logUserTypes[log.UserType];

            return {
              ...rest,
              UpdatedAt: updated_at,
              UserType: UserTypeCasted,
            };
          });

        this.setState({
          serviceData: {
            ...this.state.serviceData,
            logList: results,
          },
        });
      },
      (error) => {
        this.setState({ redirect: true });
      }
    );
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={{ pathname: "/" }} />;
    }

    if (
      typeof this.state.serviceData === "undefined" ||
      this.state.serviceData.length === 0
    ) {
      return <LoadingSkeleton />;
    }
    let uploadToken = this.state.uploadToken;
    let token = this.state.token;

    const delayContactClassObject = new DelayContact(
      this.state.thistaskId,
      this.state.preparedConfig
    );

    const task = this.state.serviceData;
    const client = { ...this.state.thisClient };

    /*

        console.log('State')
        console.log(this.state)

        */

    const myEvent = {
      id: this.state.thistaskId || "-",
      stage: task.taskStage || "-",
      summary: task.summary || task.descripcion,
      cp: this.state.thisTaskcp || "-",
      fechaEjecucion: task.executionDate,
      taskCategory: this.state.serviceData.categoryshortdescription,
      price: task.priceHelper,
    };

    myEvent.priceHelperWithoutIVA =
      Math.round((myEvent.priceHelper / 1.21 + Number.EPSILON) * 100) / 100;

    myEvent.addressStreet =
      task.addressStreet || (task.addressStreet == 0 ? 0 : "");
    myEvent.addressNumber =
      task.addressNumber || (task.addressNumber == 0 ? 0 : "");
    myEvent.addressObservations =
      task.addressObservations || (task.addressObservations == 0 ? 0 : "");
    myEvent.thisTaskcp = this.state.thisTaskcp || "-";
    myEvent.preLocation =
      !myEvent.addressStreet &&
      !myEvent.addressNumber &&
      !myEvent.addressObservations
        ? " Dirección no especificada - "
        : myEvent.addressStreet +
          " " +
          myEvent.addressNumber +
          " " +
          myEvent.addressObservations;

    myEvent.location = myEvent.preLocation + " (" + myEvent.thisTaskcp + ")";

    myEvent.priceFinal = task.priceFinal || (task.priceFinal === 0 ? 0 : "-");
    myEvent.priceHelper =
      task.priceHelper || (task.priceHelper === 0 ? 0 : "-");
    myEvent.priceHelperWithoutIVA =
      Math.round((myEvent.priceHelper / 1.21 + Number.EPSILON) * 100) / 100;
    if (myEvent.priceFinal == "-" || myEvent.priceHelper == "-") {
      myEvent.pricecomision = "-";
    } else {
      myEvent.pricecomision = task.priceFinal - task.priceHelper;
    }

    if (!isNaN(parseFloat(task.priceFinal)))
      myEvent.priceFinal = parseFloat(task.priceFinal).toFixed(2);
    if (!isNaN(parseFloat(task.priceHelper)))
      myEvent.priceHelper = parseFloat(task.priceHelper).toFixed(2);
    if (!isNaN(parseFloat(myEvent.pricecomision)))
      myEvent.pricecomision = parseFloat(myEvent.pricecomision).toFixed(2);

    myEvent.executionDate =
      task.executionDate &&
      moment(task.executionDate, "YYYY-MM-DDTHH:mm:ss").isValid()
        ? moment(task.executionDate, "YYYY-MM-DDTHH:mm:ss").format(
            "DD/MM/YYYY HH:mm"
          )
        : "-";
    myEvent.finishedAt =
      task.finishedAt &&
      moment(task.finishedAt, "YYYY-MM-DDTHH:mm:ss").isValid()
        ? moment(task.finishedAt, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY")
        : "-";
    myEvent.helperPaidAt =
      task.helperPaidAt &&
      moment(task.helperPaidAt, "YYYY-MM-DDTHH:mm:ss").isValid()
        ? moment(task.helperPaidAt, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY")
        : "-";

    myEvent.showEditDate = false;
    myEvent.askReason = true;

    // Comprobamos que podemos mostrar el botón de modificar fecha
    if (moment(task.executionDate, "YYYY-MM-DDTHH:mm:ss").isValid()) {
      if (
        moment(task.executionDate, "YYYY-MM-DDTHH:mm:ss").diff(new Date()) > 0
      ) {
        myEvent.showEditDate = true;
      }
    } else if (task.executionDate == null || task.executionDate == "") {
      myEvent.showEditDate = true;
      myEvent.askReason = false;
    }

    myEvent.phone_number = "No definido";

    // Comprobamos si el teléfono esta definido
    if (
      this.state.thisClient.ExtraUserDataPhone != null ||
      this.state.thisClient.ExtraUserDataPhone != ""
    ) {
      myEvent.phone_number = this.state.thisClient.ExtraUserDataPhone;
    }

    let diagnostico = false;
    if (this.state.serviceData.shortDescriptionName !== undefined) {
      if (
        this.state.serviceData.shortDescriptionName
          .toUpperCase()
          .includes("DIAGNÓSTICO")
      ) {
        diagnostico = true;
      }
    }

    return (
      <div className="main-container">
        <Link
          className="goBack"
          to={{
            pathname: "/servicios_asignados",
            token: this.state.token,
            helperId: this.state.helperId,
          }}
        >
          <span>
            <FontAwesomeIcon icon={faArrowLeft} size="lg" />
          </span>
        </Link>

        <div className="ficha-container">
          <div className="ficha-task">
            <div className="ficha-header-title">ID {myEvent.id}</div>
          </div>

          <div className="ficha-servicio">
            <div>
              <b>Fecha de solicitud:</b>{" "}
              {moment(task.createdAt, "YYYY-MM-DDTHH:mm:ss").format(
                "DD/MM/YYYY HH:mm"
              )}
            </div>
            <div>
              <b>Categoría:</b> {myEvent.taskCategory}
            </div>
            <div>
              <b>Estado: </b>
              {task.taskStage}
            </div>
            <div>
              <b>Descripción:</b>
              <div style={{ whiteSpace: 'pre-line' }}>
                {myEvent.summary}
              </div>
            </div>
            <div>
              <b>Precio: </b>
              {myEvent.priceHelperWithoutIVA}€ + IVA
            </div>

            <div style={{ marginTop: "1rem" }}>
              <b>Nombre del cliente:</b> {client.ExtraUserDataFirstName}{" "}
              {client.ExtraUserDataLastName}
            </div>

            {task.taskStage == "PENDIENTES DE CITA" && (
              <div>
                <b>Número de contacto: </b> {myEvent.phone_number}
              </div>
            )}

            <div style={{ marginTop: "1rem" }}>
              <b>Dirección:</b>
            </div>
            <div>
              {myEvent.preLocation} ({myEvent.cp})
            </div>

            {task.taskStage == "PENDIENTES DE CITA" && (
              <section>
                <hr
                  style={{
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                    width: "100%",
                    border: "1px solid #00cbeb",
                  }}
                />
                <TaskLogCreateModal
                  isOpen={this.state.showModalCreateLog}
                  handleBack={() =>
                    this.setState({ showModalCreateLog: false })
                  }
                  handleSubmit={this.handleCreateLog}
                />

                <div className="ficha-title">Registro de Eventos</div>
                <div className="ficha-log-contenedor-bloque">
                  <TaskLog logList={this.state.serviceData.logList} />
                </div>
                <div className="ficha-log-contenedor-buttons">
                  <button
                    className="button-documentos custom-button ficha-button-documentos"
                    onClick={() => {
                      this.setState({ showModalCreateLog: true });
                    }}
                  >
                    Enviar mensaje a Multihelpers
                  </button>
                </div>
              </section>
            )}

            <hr
              style={{
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
                width: "100%",
                border: "1px solid #00cbeb",
              }}
            />

            {task.taskStage == "ASIGNADO A PROFESIONAL" && (
              <Container>
                <Row>
                  <Col>
                    <button
                      className="custom-button custom-button1"
                      onClick={() => this.acceptTask()}
                    >
                      Aceptar
                    </button>
                  </Col>
                  <Col>
                    <button
                      className="custom-button custom-button1 rejectTaskButton"
                      onClick={() => this.rejectTask()}
                    >
                      Rechazar
                    </button>
                  </Col>
                </Row>
              </Container>
            )}

            <Container>
              <Row>
                {diagnostico && !this.state.serviceData.is_brico && (
                  <Col>
                    <ButtonDeliverDiagnostic
                      taskId={this.state.thistaskId}
                      className="custom-button custom-button1"
                    />
                  </Col>
                )}

                {task.taskStage == "PENDIENTES DE CITA" && (
                  <Col>
                    <ButtonRejectService
                      taskId={this.state.thistaskId}
                      className="custom-button custom-button3"
                      preparedConfig={this.state.preparedConfig}
                      is_brico={this.state.serviceData.is_brico == "1"}
                      MultihelpersJobCode={this.state.thistaskId}
                    />
                  </Col>
                )}
              </Row>
            </Container>

            {task.taskStage == "PENDIENTES DE CITA" && (
              <Container>
                <Row>
                  <Col>
                    <button
                      className="custom-button custom-button1"
                      onClick={() => this.proposeAppointment()}
                    >
                      Proponer Cita
                    </button>
                  </Col>
                  <Col>
                    <Button
                      text="Posponer contacto"
                      style={{ backgroundColor: "white", color: "#00cded" }}
                      onClick={() => delayContactClassObject.delayContact()}
                    />
                  </Col>
                </Row>
              </Container>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default task;
