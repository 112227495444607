import React from "react";
import Header from "../../components/header/header.component";
import Footer from "../../components/footer/footer.component";
import Config from "../../components/config/config.component";
import "./config.page.styles.scss";

const ConfigPage = (props) => {
  return (
    <div className="parent">
      <Header />
      <div className="task-list">
        <div className="task-list-title">
          <h3>Configuración</h3>
        </div>
        <Config {...props} />
      </div>
      <Footer />
    </div>
  );
};

export default ConfigPage;
