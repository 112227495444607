import React from "react";
import Header from "../../components/header/header.component";
import Footer from "../../components/footer/footer.component";
import Tasks from "../../components/tasks-assigned/tasks.component";
import "./task-asigned-list.styles.scss";

import ENDPOINTS from "../../utils/endpoints";

/*
Now, all url's to get tasks with filters from api are following the next pattern: <api_url>api/helpers/<helper_id>/<type_of_filter>

Therefor, to make it more easy to use and reproduce the url is implement as:
    - The begining (<api_url>api/helpers/) it stored in envioremet varriable called: REACT_APP_API_HELPER_URL
    - The <helper_id> is got in the component after login (Since we do not expect a large load on the api from helpers, we will always request the entire task list from the api)
    - /<type_of_filter> is defined here to make the component more flexible
 */
var url_to_get_tasks = ENDPOINTS.services.assigned;

/*
Now, all url's to get a single task with specific view are following the next pattern: <base_location>/<task_id>/<type_of_view>

Therefor, to make it more easy to use and reproduce the url is implement as:
    - The begining (<base_location>) it can be get by window.location
    - The <task_id> is got in the component
    - /<type_of_view> is defined here to make the component more flexible.
 */
var redirect_url = "/aceptar_rechazar_servicio";

/*
Message in case there are no tasks with selected filters
 */
var message_no_tasks_found =
  "No se han detectado nuevos servicios asignados sin procesar";

/**
 * Variable that display the view
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TaskAsignedList = (props) => {
  return (
    <div className="parent">
      <Header />
      <div className="task-list">
        <div className="task-list-title">
          <h3>Servicios Asignados</h3>
        </div>

        <Tasks
          {...props}
          url_to_get_tasks={url_to_get_tasks}
          redirect_url={redirect_url}
          message_no_tasks_found={message_no_tasks_found}
        />
      </div>

      <Footer />
    </div>
  );
};

export default TaskAsignedList;
