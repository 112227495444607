import React from 'react';

import Header from '../../components/header/header.component';
import Task from '../../components/create-budget/create-budget.component';
import Footer from '../../components/footer/footer.component';

const CreateBudget = (props) => (
  <div className='parent'>
    <Header />

    <Task {...props} />

    <Footer />
  </div>
);

export default CreateBudget;
