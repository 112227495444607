import React from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import LoadingSkeleton from "../loading-skeleton/loading-skeleton.component";
import PopupMessageWithoutRedirect from "../popup-message/popup-message-without-redirect.component";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/esm/Col";
import Switch from "react-bootstrap/esm/Switch";
import { Image } from "react-bootstrap";
import CustomButton from "../custom-button/custom-button.component";

// Upload file
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Constants
import ENDPOINTS from "../../utils/endpoints";

// Styles
import "./config.styles.scss";

registerPlugin(FilePondPluginImagePreview);

class Config extends React.Component {
  // AbortController and CancelTocken, to cancel the API request
  controller = new AbortController();
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);

    if (
      this.props.location.search == "" &&
      JSON.parse(localStorage.getItem("task"))?.userData == null
    ) {
      window.location.href = process.env.REACT_APP_WEB_LOGIN_URL;
    }

    this.state = {
      serviceData: [],
      userData: this.props.location.search
        ? this.props.location.search
        : JSON.parse(localStorage.getItem("task")).userData,
      redirect: false,
      token: false,
      HelperData: {},
      userMe: {},
      newLogo: null,
      autoInvoice: true,
    };

    this.handlePondFile = this.handlePondFile.bind(this);
    localStorage.setItem("task", JSON.stringify(this.state));
  }

  handlePondFile = (error, file) => {
    if (error) {
      console.log("Oh no");
      return;
    }

    let url = JSON.parse(file.serverId).url;
    this.setState({
      newLogo: url,
    });
  };

  decryptUserData() {
    const decryptedStr = this.handleHash(this.state.userData);
    const passwordArr = decryptedStr.split("password=");
    const pass = passwordArr[1];
    const usernameArr = passwordArr[0].split("username=");
    const user = usernameArr[1].slice(0, -1);

    return [user, pass];
  }

  handleHash(hash) {
    try {
      return atob(hash.substr(1));
    } catch (e) {
      console.log("Error en Handle hash");
      this.setState({ redirect: true });
    }
  }

  loginCheckAPI(urlLoginCheckAPI, userObject) {
    axios.post(urlLoginCheckAPI, userObject).then(
      (result) => {
        this.setState({ token: result.data.token });

        const preparedConfig = {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
            accept: "application/json",
          },
        };

        this.getUserMe(ENDPOINTS.userMe, preparedConfig);
      },
      (error) => {
        this.showErrorMessage("No se han podido obtener los datos del login");
        window.location.href = process.env.REACT_APP_WEB_LOGIN_URL;
      }
    );
  }

  getUserMe(urlGetHelperId, preparedConfig) {
    axios.get(urlGetHelperId, preparedConfig).then(
      (result) => {
        this.setState({
          userMe: result.data,
        });

        if (!result.data.helper_active) {
          this.setState({ redirect: true });
        }

        localStorage.setItem("task", JSON.stringify(this.state));

        this.getSelfHelperData(ENDPOINTS.selfHelperData, preparedConfig);
      },
      (error) => {
        this.showErrorMessage("No se han podido obtener los datos del login");
        this.setState({ redirect: true });
      }
    );
  }

  getSelfHelperData(urlGetHelperData, preparedConfig) {
    axios.get(urlGetHelperData, preparedConfig).then(
      (result) => {
        this.setState({
          HelperData: result.data,
        });
        this.setState({
          autoInvoice: this.state.HelperData.HelperDataAutoInvoice,
          newLogo: this.state.HelperData.HelperDataAutoInvoiceIconUrl,
        });
        localStorage.setItem("task", JSON.stringify(this.state));
      },
      (error) => {
        this.showErrorMessage("No se han podido obtener los datos del usuario");
        this.setState({ redirect: true });
      }
    );
  }

  componentDidMount() {
    let secureArray = this.decryptUserData();
    const userObject = {
      username: secureArray[0],
      password: secureArray[1],
    };
    const urlLoginCheckAPI = ENDPOINTS.urlLoginCheckAPI;

    this.loginCheckAPI(urlLoginCheckAPI, userObject, secureArray);
  }

  componentWillUnmount() {
    this.signal.cancel("API is being canceled");
    this.controller.abort();
  }

  showErrorMessage(error) {
    PopupMessageWithoutRedirect("error", error);
  }

  handleSubmit = () => {
    const preparedConfig = {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
        accept: "application/json",
      },
    };

    const url =
      ENDPOINTS.helperData + "/" + this.state.HelperData.HelperDataUid;

    axios
      .patch(
        url,
        {
          HelperDataAutoInvoice: this.state.autoInvoice,
          HelperDataAutoInvoiceIconUrl: this.state.newLogo,
        },
        preparedConfig
      )
      .then(
        (result) => {
          PopupMessageWithoutRedirect(
            "success",
            "Configuración actualizada correctamente"
          );
          this.setState({
            HelperData: result.data[0],
          });
        },
        (error) => {
          PopupMessageWithoutRedirect(
            "error",
            "No se ha podido actualizar la configuración"
          );
        }
      );
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={{ pathname: "/servicios" }} />;
    }

    if (
      Object.keys(this.state.HelperData).length === 0 &&
      this.state.HelperData.constructor === Object
    ) {
      return <LoadingSkeleton />;
    }

    return (
      <Container style={{ marginTop: "1rem" }}>
        <Card>
          <Card.Body>
            <Card.Title>Configuración de facturación automática</Card.Title>
            <Row
              style={{
                marginTop: "40px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Switch
                id="facturacion_automatica"
                label="Facturación automática"
                type="switch"
                checked={this.state.autoInvoice}
                onChange={() => {
                  this.setState({
                    autoInvoice: !this.state.autoInvoice,
                  });
                }}
              />
            </Row>
            <Row style={{ marginTop: "40px" }}>
              <CustomButton onClick={this.handleSubmit}>
                Actualizar
              </CustomButton>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}

export default Config;
