import Swal from "sweetalert2";

import './popup-message.styles.scss';

const PopupConfirmWithoutIconAndCancel = (title, route,confirmButtonText,cancelButtonText) => {
  Swal.fire({
    title: title,
    allowOutsideClick: false,
    showConfirmButton: true,
    showCancelButton: true,
    showCloseButton: false,
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    customClass: 'mh-modal'
  }).then((result) => {
    if(result.value) {
        window.location = '/' + route;
    }
  });
};

export default PopupConfirmWithoutIconAndCancel;
