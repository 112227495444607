import React from 'react';
import './header.styles.scss';
import Logo from '../../assets/images/logo-mh.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../navbar/Navbar';



const Header = () => (
  <div>
    <div className='header'>
        <Navbar />
        <img className='logo' src={ Logo } alt="multihelpers" />
    </div>
  </div>
  
);

export default Header;
