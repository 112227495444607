import React from "react";

import axios from "axios";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

import PopupMessage from "../../components/popup-message/popup-message.component";
import PopupConfirm from "../../components/popup-message/popup-confirm.component";
import CustomButton from "../../components/custom-button/custom-button.component";
import Header from "../../components/header/header.component";
import Footer from "../../components/footer/footer.component";

import "./upload-before-photos.styles.scss";

import ENDPOINTS from "../../utils/endpoints";

registerPlugin(FilePondPluginImagePreview);

class UploadBeforePhotos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      taskId: this.props.match.params.taskId
        ? this.props.match.params.taskId
        : JSON.parse(localStorage.getItem("task")).taskId,
      userData: this.props.location.query
        ? this.props.location.query.userData
        : JSON.parse(localStorage.getItem("task")).userData,
      token: this.props.location.query
        ? this.props.location.query.token
        : JSON.parse(localStorage.getItem("task")).token,
      uploadToken: this.props.location.query
        ? this.props.location.query.uploadToken
        : JSON.parse(localStorage.getItem("task")).uploadToken,
      uploadedFiles: [],
    };

    this.handlePondFile = this.handlePondFile.bind(this);
    this.handlePondFileRemove = this.handlePondFileRemove.bind(this);
  }

  handleSubmitClick(uploadedFiles) {
    const preparedConfig = {
      headers: {
        Authorization: "Bearer " + this.state.token,
        accept: "application/json",
      },
    };

    const url = ENDPOINTS.services.document;

    axios
      .post(
        url,
        {
          MultihelpersJobCode: this.state.taskId,
          documents: uploadedFiles,
          source: "070B0B47-F36B-1410-8FBE-005CABA086B5",
          type: "0A0B0B47-F36B-1410-8FBE-005CABA086B5",
        },
        preparedConfig
      )
      .then(
        (result) => {
          PopupMessage(
            "success",
            "Tus fotos de antes se han subido correctamente",
            this.state.taskId,
            this.state.taskId + "/documentos"
          );
        },
        (error) => {
          PopupMessage("error", "Algo no va bien", "");
        }
      );
  }

  handlePondFile = (error, file) => {
    if (error) {
      console.log("Oh no");
      return;
    }

    let backedUrl = JSON.parse(file.serverId).url;
    this.setState({
      uploadedFiles: this.state.uploadedFiles.concat(backedUrl),
    });
  };

  handlePondFileRemove = (error, file) => {
    let urlSubmitArray = this.state.uploadedFiles.filter((el) => {
      return el !== JSON.parse(file.serverId).url;
    });

    this.setState({ uploadedFiles: urlSubmitArray });
  };

  handleBackAction = (e) => {
    e.preventDefault();
    PopupConfirm(
      "warning",
      "¿Estás seguro de que quieres salir? No has subido ninguna foto",
      this.state.taskId,
      this.state.taskId + "/documentos"
    );
  };

  render() {
    let taskId = this.state.taskId;
    return (
      <div className="parent">
        <Header />

        <div className="upload-before-photos">
          <h3 className="step-title">FOTOS ANTES</h3>
          <Link
            className="step-back"
            id="before-photos-link"
            onClick={this.handleBackAction}
            to={{ pathname: "/" + taskId }}
          >
            <span>
              <FontAwesomeIcon icon={faArrowLeft} />
            </span>
          </Link>

          <div className="file-uploader">
            <FilePond
              onprocessfile={this.handlePondFile}
              onremovefile={this.handlePondFileRemove}
              allowMultiple={true}
              imagePreviewMaxHeight={100}
              styleButtonRemoveItemPosition="left"
              styleButtonProcessItemPosition="bottom"
              styleLoadIndicatorPosition="right"
              styleProgressIndicatorPosition="right"
              // captureMethod="camera"
              instantUpload={true}
              checkValidity={true}
              dropValidation={true}
              allowRevert={true}
              name="image"
              maxFiles={10}
              server={{
                url:
                  process.env.REACT_APP_FILEUPLOADER_URL +
                  "images/" +
                  this.state.taskId,
                process: {
                  method: "POST",
                  headers: {
                    Authorization: "Basic " + this.state.uploadToken,
                  },
                },
                revert: {
                  onerror: (response) => response.data,
                },
              }}
              labelFileProcessingComplete=" Subida completada"
              labelTapToCancel="Toca para cancelar"
              labelButtonAbortItemLoad="Cancelar descarga"
              labelButtonRemoveItem="Borrar archivo"
              labelButtonRetryItemLoad="Cargando"
              labelButtonRetryItemProcessing="Reintentar"
              labelButtonUndoItemProcessing="Deshacer"
              labelDecimalSeparator=","
              labelFileAdded="Archivo Añadido"
              labelFileCountPlural="Archivos"
              labelFileCountSingular="Archivo"
              labelFileLoadError="Error al subir"
              labelFileLoading="Cargando"
              labelFileProcessing="Subiendo"
              labelFileProcessingAborted="Subida cancelada"
              labelFileProcessingError="Error al subir archivo"
              labelFileRemoved="Archivo eliminado"
              labelFileSizeNotAvailable="Tamaño no disponible"
              labelFileWaitingForSize="Comprobando tamaño"
              labelIdle="<div class='custom-plus-div'>Agregar fotos&nbsp;&nbsp;<span class='custom-plus-icon'></span></div>"
              labelTapToRetry="Toca para reintentar"
              labelTapToUndo="Toca para deshacer"
              labelThousandsSeparator=""
            />

            <CustomButton
              className={
                "custom-button " +
                (this.state.uploadedFiles.length ? "" : "grey-button")
              }
              disabled={!this.state.uploadedFiles.length}
              onClick={() => this.handleSubmitClick(this.state.uploadedFiles)}
            >
              Subir imágenes
            </CustomButton>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default UploadBeforePhotos;
