import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TaskPage from "./pages/task-page/task-page.component";
import VisitPage from "./pages/visit-page/visit-page.component";
import TaskList from "./pages/task-list/task-list.component";
import TaskCalendar from "./pages/task-calendar/task-calendar.component";
import TasksAvailable from "./pages/tasks-available/tasks-available.component";
import AvailableTask from "./pages/task-available/task-available.component";
import UploadBeforePhotos from "./pages/upload-before-photos/upload-before-photos.component";
import UploadBeforeVisitPhotos from "./pages/upload-before-visit-photos/upload-before-visit-photos.component";
import UploadAfterPhotos from "./pages/upload-after-photos/upload-after-photos.component";
import UploadInvoice from "./pages/upload-invoice/upload-invoice.component";
import NotFoundPage from "./pages/not-found-page/not-found-page.component";
import "./App.css";
import TaskImages from "./pages/task-images/task-images.component";
import AcceptCalendar from "./pages/accepted-task-calendar/accepted-task-calendar.component";
import Documents from "./pages/documents/documents.component";
import VisitDocuments from "./pages/visit-documents/visit-documents.component";
import TaskAvailableImages from "./pages/task-available-images/task-images.component";
import TaskScheduleVisit from "./pages/task-visit-schedule/task-schedule-visit.component";
import VisitCalendar from "./pages/task-visit-schedule-calendar/task-visit-schedule-calendar.component";
import ChangeDateTaskCalendar from "./pages/task-change-date-schedule-calendar/task-change-date-schedule-calendar.component";
import TaskAsignedList from "./pages/task-asigned-list/task-asigned-list.component";
import TaskAceptReject from "./pages/task-acept-reject/task-page.component";
import CreateBudget from "./pages/create-budget/create-budget.component";
import UploadConformityDocument from "./pages/upload-conformity-document/upload-conformity-document.component";
import FinishedList from "./pages/finished/list/finished-list.page";
import ConfigPage from "./pages/config/config.page.component";
import InvoiceList from "./pages/invoices/list/invoice-list.page";

const App = () => (
  <Router>
    <Switch>
      <Route exact path="/config" component={ConfigPage} />
      <Route exact path="/servicios" component={TaskList} />
      <Route exact path="/servicios_finalizados" component={FinishedList} />
      <Route exact path="/servicios_asignados" component={TaskAsignedList} />
      <Route exact path="/calendario" component={TaskCalendar} />
      <Route exact path="/servicios_disponibles" component={TasksAvailable} />
      <Route exact path="/invoices" component={InvoiceList} />
      <Route
        exact
        path="/:taskId/subir-fotos-antes"
        component={UploadBeforePhotos}
      />
      <Route
        exact
        path="/:taskId/presupuestar/:tipo"
        component={CreateBudget}
      />
      <Route
        exact
        path="/:taskId/aceptar_rechazar_servicio"
        component={TaskAceptReject}
      />
      <Route
        exact
        path="/:taskId/subir-fotos-antes-visita"
        component={UploadBeforeVisitPhotos}
      />
      <Route
        exact
        path="/:taskId/subir-fotos-despues"
        component={UploadAfterPhotos}
      />
      <Route exact path="/:taskId/subir-factura" component={UploadInvoice} />
      <Route exact path="/:taskId/calendario" component={AcceptCalendar} />
      <Route exact path="/:taskId" component={TaskPage} />

      <Route exact path="/visita/:taskId" component={VisitPage} />
      <Route exact path="/:taskId/disponible" component={AvailableTask} />
      <Route
        exact
        path="/:taskId/:MultihelpersJobUid/ver-fotos"
        component={TaskImages}
      />
      <Route
        exact
        path="/:taskId/disponible/ver-fotos"
        component={TaskAvailableImages}
      />
      <Route exact path="/:taskId/documentos" component={Documents} />
      <Route
        exact
        path="/:taskId/documentos-visita"
        component={VisitDocuments}
      />
      <Route
        exact
        path="/:taskId/agendar_visita"
        component={TaskScheduleVisit}
      />
      <Route
        exact
        path="/:taskId/agendar_visita_calendario"
        component={VisitCalendar}
      />
      <Route
        exact
        path="/:taskId/cambiar_fecha/:askReason/:comingFromAsignVisit"
        component={ChangeDateTaskCalendar}
      />
      <Route
        exact
        path="/:taskId/subir-documento-conformidad"
        component={UploadConformityDocument}
      />

      <Route path="/" component={NotFoundPage} />
    </Switch>
  </Router>
);

export default App;
